<template>
  <div :style="style" :class="cls">
    <vue-editor
      :class="cls2"
      :value="content"
      :editorOptions="editorOptions"
      :editor-toolbar="customToolbar"
      :customModules="customModules"
      useCustomImageHandler
      @image-added="handleImageAdded"
      @input="handleChange"
    />
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize";
import mixinForm from "@/mixins/mixinForm";

function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}

const fontList = ['Nanum Gothic', 'Nanum Myeongjo', 'Nanum Square', 'Nanum Square Round'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
const fontSize = Quill.import('attributors/style/size');
fonts.whitelist = fontNames;
fontSize.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px']
Quill.register(fonts, true);
Quill.register(fontSize, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
    let fontName = getFontName(font);
    fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
        "content: '" + font + "';" +
        "font-family: '" + font + "', sans-serif;" +
        "}" +
        ".ql-font-" + fontName + "{" +
        " font-family: '" + font + "', sans-serif;" +
        "}";
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
  name: "EditorCommon",

  /*** mixins ***/
  mixins: [mixinForm],

  /*** props ***/
  props: {
    content: {
      type: String,
      default: "",
    },
    widNm: {
      type: String,
      default: "",
    },
  },

  /*** components ***/
  components: { VueEditor },

  /*** data ***/
  data() {
    return {
      customModules: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize },
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'font': fonts.whitelist }],
        [{ 'size': ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px'] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"], // remove formatting button
      ],
      editorOptions: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
        toolbar: {
          container: [["bold", "italic", "underline", "strike"], ["image"]],
          handlers: {
            image: this.myHandleImage,
          },
        },
      },
      html: "",
      file: null,
      files: [],
    };
  },

  /*** created ***/
  created() {
    this.editorOptions.placeholder = this.holderNm;
  },

  /*** computed ***/
  computed: {
    cls() {
      let cls = "quill-editor ";
      cls += this.wpCls || "";
      return cls;
    },
    cls2() {
      let cls = "";
      cls += "input-detail ";
      cls += this.wpCls2 || "";
      return cls;
    },
    style() {
      return this.widNm ? "width: " + this.widNm + "px;" : "100%";
    },
  },

  /*** methods ***/
  methods: {
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("contentEsntlId", "111111");
      formData.append("fileList", this.files);
      const url = "/admin/content/task/content/file/insert";
      const rs = await this.$axios({ url, type: "MULTI", params: formData });
      const imgUrl = rs.data;
      resetUploader();
      this.makeLog([formData, rs, imgUrl], "err");
    },
    handleChange(val) {
      this.$emit("update:content", val);
    },
  },
};
</script>

<style scoped lang="scss">
.ql-picker-item[data-value='10px']::before, .ql-picker-label[data-value='10px']::before {
  content: '10px' !important;
}

.ql-picker-item[data-value='12px']::before, .ql-picker-label[data-value='12px']::before {
  content: '12px' !important;
}

.ql-picker-item[data-value='14px']::before, .ql-picker-label[data-value='14px']::before {
  content: '14px' !important;
}

.ql-picker-item[data-value='16px']::before, .ql-picker-label[data-value='16px']::before {
  content: '16px' !important;
}

.ql-picker-item[data-value='18px']::before, .ql-picker-label[data-value='18px']::before {
  content: '18px' !important;
}

.ql-picker-item[data-value='20px']::before, .ql-picker-label[data-value='20px']::before {
  content: '20px' !important;
}

.ql-picker-item[data-value='24px']::before, .ql-picker-label[data-value='24px']::before {
  content: '24px' !important;
}

.ql-picker-item[data-value='30px']::before, .ql-picker-label[data-value='30px']::before {
  content: '30px' !important;
}

.ql-picker-item[data-value='32px']::before, .ql-picker-label[data-value='32px']::before {
  content: '32px' !important;
}

.ql-picker-item[data-value='36px']::before, .ql-picker-label[data-value='36px']::before {
  content: '36px' !important;
}
</style>
