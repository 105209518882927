<template>
  <div class="container-wrapper">
    <template v-if="!G_NAV_CHECK_LOGIN">
      <header-wrapper class="header-wrapper" />
      <div class="root-wrapper">
        <aside-wrapper />
        <div class="content mb-80">
          <div class="content-wrap">
            <main-title />
            <sub-title />
            <router-view class="mx-a mb-16" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <router-view class="mx-a mb-16" />
    </template>
    <!-- Global Loader -->
    <!-- <loading-bar :active="G_LOADING" :is-full-page="true" /> -->
    <!-- Global Modal -->
    <modal-common />
  </div>
</template>
<script>
import HeaderWrapper from "@/components/common/HeaderWrapper.vue";
import AsideWrapper from "@/components/common/AsideWrapper.vue";
import MainTitle from "@/components/common/content-wrapper/MainTitle";
import SubTitle from "@/components/common/content-wrapper/SubTitle";

import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { HeaderWrapper, AsideWrapper, MainTitle, SubTitle },

  provide() {
    return {};
  },

  data() {
    return {};
  },

  beforeCreate() {},

  created() {
    this.$store.dispatch("A_SHOW_BELL", false);
  },

  computed: {
    ...mapGetters(["G_NAV_CHECK_LOGIN", "G_LOADING", "G_NAV_ALL"]),
  },

  methods: {},

  async errorCaptured(err, vm, info) {
    await this.$store.dispatch("A_MODAL_INFO", {
      ...this.G_MODAL_RESET,
      type: this.TYPE_ALERT,
      size: "tiny",
      bodyTx: this.ERR_XHR,
      btnPrmyNm: "확인",
      btnScndClr: "s",
    });
    await this.$store.dispatch("A_MODAL_TOGGLE", true);
    console.error("========== err.st ==========");
    console.log("%c == err ", "color: #72950B");
    console.log(err);
    console.log("%c == component", "color: #72950B");
    console.log(vm);
    console.log("%c == info", "color: #72950B");
    console.log(info);
    console.error("========== err.ed ==========");
    return false;
  },

  beforeRouteEnter(to, from, next) {
    console.log(to)
    console.log(from)
    // this.toRoute.path = to.fullPath;
    // this.toRoute.meta = to.meta.id;
    // if (this.isReset) {
    //   next();
    // } else {
    //   next(false);
    //   if (!this.isReset) {
    //     this.onPageReset();
    //   }
    // }
  },
};
</script>
<style lang="scss">
.container-wrapper {
  min-height: 100vh;
  background: #fff;
  @include flexCol;
  @include flex;

  .header-wrapper {
    flex-grow: 0;
  }

  .root-wrapper {
    @include flex;
    flex-grow: 1;
    background: var(--gray10);
  }
}
</style>
