import { render, staticRenderFns } from "./UploaderBoxImg.vue?vue&type=template&id=3cb0775a&scoped=true&"
import script from "./UploaderBoxImg.vue?vue&type=script&lang=js&"
export * from "./UploaderBoxImg.vue?vue&type=script&lang=js&"
import style0 from "./UploaderBoxImg.vue?vue&type=style&index=0&id=3cb0775a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb0775a",
  null
  
)

export default component.exports