import _ from 'lodash'
export default {
  G_MEMBER_CODE: (state) => {
    return state.MEMBER_CODE
  },
  G_PARTENER_CODE: (state) => {
    return state.PARTENER_CODE
  },
  G_PAYMENT_DATA: (state) => {
    return state.PAYMENT_DATA
  },
  G_IS_CONFIRM: (state) => {
    return state.IS_CONFIRM
  },
  G_ERROR_CODE: (state) => {
    return state.ERROR_CODE
  },
  G_AGREE_CODE: (state) => {
    return state.AGREE_CODE
  },
}
