<template>
  <!-- 결제유형 -->
  <div class="info-container">
    <!-- 항목 번호 -->
    <div class="info-content-wrap">
      <span class="header-category">항목 번호</span>
      <span class="content-bold">{{ esntlId }}</span>
    </div>
    <!-- 파트너명 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">파트너명</span>
      <span>{{ infoData.name }}</span>
    </div>
    <!-- 카테고리 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">카테고리</span>
      <span>{{ infoData.highCategory }} > {{ infoData.lowCategory }}</span>
    </div>
    <!-- 결제 항목 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">결제 항목</span>
      <span>{{ infoData.serviceName }}</span>
    </div>
    <!-- 월 이용료 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="header-category">월 이용료</span>
      <span class="content-bold">{{ formatPrice(infoData.price) }}원</span>
    </div>
    <!-- 해지위약금 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">해지위약금</span>
      <span>{{ infoData.penalty === "false" ? "있음" : "없음" }}</span>
    </div>
    <div class="info-divider" style="margin-bottom: 18px" />
    <!-- 약정기간 별 할인율 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="header-category">약정기간 별 할인율</span>
      <div class="flex">
        <span class="content-bold" style="margin-right: 100px">1개월</span>
        <div class="content-discount">
          <span>0%</span>
        </div>
      </div>
    </div>
    <template v-if="infoData">
      <div
        class="info-content-wrap"
        v-for="(discount, index) in infoData.serviceItemList"
        :key="index"
      >
        <span class="header-category"></span>
        <div class="flex">
          <span class="content-bold" style="margin-right: 100px"
            >{{ discount.month }}개월</span
          >
          <div class="content-discount">
            <span>{{ discount.discountPercent }}%</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalPaymentNumber",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      esntlId: "",
      discountList: [
        { title: "2개월", value: 3 },
        { title: "3개월", value: 6 },
        { title: "4개월", value: 9 },
        { title: "5개월", value: 12 },
        { title: "6개월", value: 15 },
        { title: "7개월", value: 18 },
        { title: "8개월", value: 21 },
        { title: "9개월", value: 24 },
        { title: "10개월", value: 27 },
        { title: "11개월", value: 30 },
        { title: "12개월", value: 50 },
      ],
      infoData: null,
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_MEMBER_CODE"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.esntlId = this["G_MEMBER_CODE"];
        this.getPaymentNumber();
      } else {
        this.esntlId = "";
        this.infoData = null;
      }
    },
  },

  /*** methods ***/
  methods: {
    async getPaymentNumber() {
      const rs = await this.$axios({
        url: "/admin/popup/service",
        params: { esntlId: this["G_MEMBER_CODE"] },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData;
      } else {
        console.log("결제 항목 조회 실패");
      }
    },

    formatPrice(price) {
      return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  padding: 22px;
}
.info-header-wrap {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-sub-category {
  color: #babdcc;
  font-size: 14px;
  font-weight: 700;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-category {
  color: #828699;
  min-width: 200px;
}
.content-bold {
  font-weight: 700;
  font-size: 14px;
}
.info-divider {
  width: 100%;
  height: 1px;
  background: #d8dae5;
}
.content-discount {
  min-width: 50px;
  text-align: right;
}
</style>
