<template>
  <!-- 기본정보 -->
  <div
    class="info-container"
    style="max-height: 80vh; overflow: auto"
    v-if="infoData"
  >
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between">
      <div class="flex-align-center">
        <span class="header-category">파트너 기본정보</span>
      </div>
    </div>
    <!-- 관리자 유형 -->
    <div class="info-content-wrap">
      <span class="content-category">관리자 유형</span>
      <span>{{ getCompStatus(infoData.adminType) }}</span>
    </div>
    <!-- 관리자 이름 -->
    <div class="info-content-wrap">
      <span class="content-category">관리자 이름</span>
      <span>{{ infoData.name }} {{ infoData.gender }}</span>
    </div>
    <!-- 휴대폰번호 -->
    <div class="info-content-wrap">
      <span class="content-category">휴대폰번호</span>
      <span v-if="infoData.phone">{{ formatPhone(infoData.phone) }}</span>
    </div>
    <!-- 아이디 -->
    <div class="info-content-wrap">
      <span class="content-category">아이디</span>
      <span>{{ infoData.email }}</span>
    </div>
    <!-- 거주지역 -->
    <div class="info-content-wrap">
      <span class="content-category">거주지역</span>
      <span>{{ infoData.addr }} {{ infoData.addr2 }}</span>
    </div>
    <!-- 활동지역 -->
    <div class="info-content-wrap">
      <span class="content-category">활동지역</span>
      <span>{{ infoData.busiArea }}</span>
    </div>
    <!-- 분야 -->
    <div class="info-content-wrap">
      <span class="content-category">분야</span>
      <span
        >{{ infoData.highSpecialtyTitle }} >
        {{ infoData.lowSpecialtyTitle }}</span
      >
    </div>
    <!-- 계약 사업자 정보 -->
    <template v-if="infoData.adminType === 'C'">
      <div
        class="info-header-wrap flex-space-between"
        style="border-top: 1px solid #d8dae6"
      >
        <div class="flex-align-center">
          <span class="header-category">계약 사업자 정보</span>
        </div>
      </div>
      <!-- 상호명 -->
      <div class="info-content-wrap">
        <span class="content-category">상호명</span>
        <span>{{ infoData.compName }}</span>
      </div>
      <!-- 사업자등록번호 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자등록번호</span>
        <span>{{ infoData.compRegNumber }}</span>
      </div>
      <!-- 대표자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">대표자 이름</span>
        <span>{{ infoData.compCeoName }}</span>
      </div>
      <!-- 사업장 소재지 -->
      <div class="info-content-wrap">
        <span class="content-category">사업장 소재지</span>
        <span>{{ infoData.compAddr }} {{ infoData.compAddr2 }}</span>
      </div>
      <!-- 사업자 구분 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자 구분</span>
        <span>{{ infoData.compClass }}</span>
      </div>
      <!-- 업태 -->
      <div class="info-content-wrap">
        <span class="content-category">업태</span>
        <span>{{ infoData.compStatus }}</span>
      </div>
      <!-- 업종 -->
      <div class="info-content-wrap">
        <span class="content-category">업종</span>
        <span>{{ infoData.compType }}</span>
      </div>
    </template>
    <!-- 계약 정보 -->
    <template v-if="infoData.contractStatus">
      <div
        class="info-header-wrap flex-space-between"
        style="border-top: 1px solid #d8dae6"
      >
        <div class="flex-align-center">
          <span class="header-category">계약 정보</span>
        </div>
      </div>
      <!-- 계약현황 -->
      <div class="info-content-wrap">
        <span class="content-category">계약현황</span>
        <span style="margin-right: 10px">{{
          getContractStatus(infoData.contractStatus)
        }}</span>
        <span style="color: #babdcc" v-if="infoData.contractEndDate"
          >{{ infoData.contractEndDate }}까지</span
        >
      </div>
      <!-- 승인일시 -->
      <div class="info-content-wrap">
        <span class="content-category">승인일시</span>
        <span>{{ infoData.contractStartDate }}</span>
      </div>
      <!-- 계약번호 -->
      <div class="info-content-wrap">
        <span class="content-category">계약번호</span>
        <span>{{ infoData.contractEsntlId }}</span>
      </div>
      <!-- 파트너코드 -->
      <div class="info-content-wrap">
        <span class="content-category">파트너코드</span>
        <span>{{ infoData.esntlId }}</span>
      </div>
      <!-- 계약 세부사항 -->
      <div
        class="info-header-wrap flex-space-between"
        style="border-top: 1px solid #d8dae6"
      >
        <div class="flex-align-center">
          <span class="header-category">계약 세부사항</span>
        </div>
      </div>
      <!-- 자동결제 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">자동결제 수수료 (VAT포함)</span>
        <span>{{ infoData.commission }}%</span>
      </div>
      <!-- 해지위약금 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">해지위약금 수수료 (VAT포함)</span>
        <span>{{ infoData.cancelPenalty }}%</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalPartenerCode",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      esntlId: "",
      infoData: null,
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_PARTENER_CODE"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.esntlId = this["G_PARTENER_CODE"];
        this.getPartenerCode();
      } else {
        this.esntlId = "";
        this.infoData = null;
      }
    },
  },

  /*** methods ***/
  methods: {
    async getPartenerCode() {
      const rs = await this.$axios({
        url: "/admin/popup/partner",
        params: { esntlId: this.esntlId },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData;
      } else {
        console.log("파트너 정보 조회 실패");
      }
    },
    getCompStatus(type) {
      switch (type) {
        case "C":
          return "사업자";
        case "P":
          return "개인";
      }
    },
    getContractStatus(type) {
      switch (type) {
        case "USED":
          return "계약중";
        case "END":
          return "계약만료";
        case "TERM":
          return "중도해지";
      }
    },
    formatPhone(num) {
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  min-width: 250px;
}
</style>
