<template>
  <div class="profile d-flex align-items-center" v-if="profile">
    <div
      class="user_photo flex-justify-center"
      style="cursor: pointer"
      @click="goMyInfo"
    >
      <img src="@/assets/image/profileImg.svg" alt="avatar placeholder" style="width: 56px; height: 56px" v-if="!profileImageSrc"/>
      <img
        :src="profileImageSrc"
        alt="avatar placeholder"
        style="width: 56px; height: 56px"
        v-else
      />
    </div>
    <div
      class="user_name text-center f-white fr-14 flex-justify-center"
      v-if="profile"
    >
      {{ profile.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  data() {
    return {
      profileImageSrc: "",
      profile: null,
    };
  },
  mounted() {
    this.getProfileData();
  },
  methods: {
    async getProfileData() {
      const rs = await this.$axios({
        url: "/partner/myInfo",
        params: {},
      });
      if (rs.result === "SUC") {
        this.profile = rs.resultData.length > 0 ? rs.resultData[0] : null;
        if (this.profile) {
          if (this.profile.profileImage) {
            this.profileImageSrc =
              "https://medal.sailors.kr/upload/partner/" +
              this.profile.profileImage;
          }
        }

        console.log("프로필 조회 성공");
      } else {
        console.log("프로필 조회 실패");
      }
    },
    goMyInfo() {
      this.$router.push({
        path: `/myInfo/company`,
        meta: { id: "1-1-1" },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
