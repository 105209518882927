import _ from 'lodash'
export default {
  M_PREVIEW_COURSE_NAME (state, name) {
    state.COURSE_NAME = name
  },
  M_PREVIEW_TASK_NAME (state, name) {
    state.TASK_NAME = name
  },
  M_PREVIEW_TASK_TITLE (state, title) {
    state.TASK_TITLE = title
  },
  M_PREVIEW_TASK_ID (state, id) {
    state.TASK_ID = id
  },
  M_PREVIEW_CUSTOMER_TASK_ID (state, id) {
    state.CUSTOMER_TASK_ID = id
  },
  M_PREVIEW_COURSE_ID (state, id) {
    state.COURSE_ID = id
  },
  M_PREVIEW_TASK_DAY (state, id) {
    state.TASK_DAY = id
  },
  M_PREVIEW_TASK_INDEX (state, id) {
    state.TASK_INDEX = id
  },
  M_PREVIEW_FEED_TYPE (state, type) {
    state.FEED_TYPE = type
  },
  M_PREVIEW_USER_NAME (state, type) {
    state.USER_NAME = type
  },
  M_PREVIEW_RESET(state) {
    state.COURSE_NAME = ''
    state.TASK_NAME = ''
    state.TASK_TITLE = ''
    state.TASK_ID = ''
    state.CUSTOMER_TASK_ID = ''
    state.COURSE_ID = ''
    state.TASK_DAY = ''
    state.TASK_INDEX = ''
    state.FEED_TYPE = ''
    state.USER_NAME = ''
  }
}
