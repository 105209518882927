<template>
  <div>
    <div class="current-bar pyx mb-16" v-if="G_NAV_SHOW">
      <router-link
        v-for="nav in G_NAV_SIBLINGS"
        :key="nav.id"
        :to="nav.path"
        :class="`depth2 fm-16 ${G_NAV_ID.startsWith(nav.id) ? 'selected' : ''}`"
      >
        {{ nav.title }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SubTitle",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["G_NAV_ID", "G_NAV_SIBLINGS", "G_NAV_SHOW"]),
  },
};
</script>

<style scoped lang="scss">
</style>
