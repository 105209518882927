<template>
  <!-- 정산정보 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between">
      <div class="flex-align-center">
        <span class="header-category">정산정보</span>
      </div>
    </div>
    <!-- 정산방법 -->
    <div class="info-content-wrap">
      <span class="content-category">정산방법</span>
      <span>계좌입금</span>
    </div>
    <!-- 입금계좌 -->
    <div class="info-content-wrap" style="align-items: flex-start">
      <span class="content-category">입금계좌</span>
      <span v-if="infoData.accountHolder"
        >{{ infoData.bankName }} {{ infoData.accountNumber }} (예금주:{{
          infoData.accountHolder
        }})</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBankInfo",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      infoData: null,
    };
  },

  /*** created ***/
  async mounted() {
    await this.getBankData();
  },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    async getBankData() {
      const rs = await this.$axios({
        url: "/partner/myInfo/bank/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData.editData;
      } else {
        console.log("정산정보 조회 실패");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
</style>
