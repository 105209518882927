import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { makeRoutes, convertLiteral } from "@/modules/util";
import nav from "@/assets/json/nav.js";
import axios from "axios";

Vue.use(VueRouter);

store.dispatch("A_NAV_ALL", nav);

const routes = makeRoutes(store.state.NAV.ALL, []);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let id = to.meta.id;
  let fromPath = store.state.NAV.LAST_PATH;
  let auth = localStorage.getItem("auth");
  let adminAuth = to.meta.auth;
  let adminAuthList =
    auth === "ADM" ? localStorage.getItem("authList").split(",") : "";
  if (id.substr(0, 1) === "0") {
    next()
  } else if (!auth) {
    next(fromPath)
  } else if (auth === 'COM') {
    next()
  } else {
    if(adminAuthList.includes(adminAuth)) {
      next()
    } else {
      next(fromPath)
    }
  }
});

router.afterEach((to, from) => {
  let id = to.meta.id;
  store.dispatch("A_NAV_SHOW", true);
  store.dispatch("A_NAV_ID", id);
  store.dispatch("A_NAV_LAST_PATH", to.fullPath);
  if (id.substr(0, 1) !== "0" && localStorage.getItem("auth") === "COM") {
    store.dispatch("A_BELL_COUNT", {});
  } else {
    store.dispatch("A_SHOW_BELL", false);
  }
});

export default router;
