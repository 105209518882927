const A_PREVIEW_COURSE_NAME = ({ commit }, payload) => {
  commit("M_PREVIEW_COURSE_NAME", payload);
};

const A_PREVIEW_TASK_NAME = ({ commit }, payload) => {
  commit("M_PREVIEW_TASK_NAME", payload);
};

const A_PREVIEW_TASK_TITLE = ({ commit }, payload) => {
  commit("M_PREVIEW_TASK_TITLE", payload);
};

const A_PREVIEW_TASK_ID = ({ commit }, payload) => {
  commit("M_PREVIEW_TASK_ID", payload);
};

const A_PREVIEW_CUSTOMER_TASK_ID = ({ commit }, payload) => {
  commit("M_PREVIEW_CUSTOMER_TASK_ID", payload);
};

const A_PREVIEW_COURSE_ID = ({ commit }, payload) => {
  console.log(payload);
  commit("M_PREVIEW_COURSE_ID", payload);
};

const A_PREVIEW_TASK_DAY = ({ commit }, payload) => {
  console.log(payload);
  commit("M_PREVIEW_TASK_DAY", payload);
};

const A_PREVIEW_TASK_INDEX = ({ commit }, payload) => {
  console.log(payload);
  commit("M_PREVIEW_TASK_INDEX", payload);
};

const A_PREVIEW_FEED_TYPE = ({ commit }, payload) => {
  commit("M_PREVIEW_FEED_TYPE", payload);
};

const A_PREVIEW_USER_NAME = ({ commit }, payload) => {
  commit("M_PREVIEW_USER_NAME", payload);
};

const A_PREVIEW_RESET = ({ commit }) => {
  commit("M_PREVIEW_RESET");
};

export default {
  A_PREVIEW_COURSE_NAME,
  A_PREVIEW_TASK_NAME,
  A_PREVIEW_TASK_TITLE,
  A_PREVIEW_TASK_ID,
  A_PREVIEW_CUSTOMER_TASK_ID,
  A_PREVIEW_COURSE_ID,
  A_PREVIEW_TASK_DAY,
  A_PREVIEW_TASK_INDEX,
  A_PREVIEW_FEED_TYPE,
  A_PREVIEW_USER_NAME,
  A_PREVIEW_RESET,
};
