<template>
  <transition name="modal">
    <div class="modal" v-show="G_MODAL_TOGGLE" @click.stop.prevent="">
      <!--* Alert -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_ALERT" :class="wrapCls">
        <div :class="`modal-content p-32 ${G_MODAL_INFO.size}`">
          <div class="py-16 w-100 text-center">
            <span
              :class="`${G_MODAL_INFO.bodyCls} fr-h6 f-g80`"
              style="font-size: 14px"
              v-html="G_MODAL_INFO.bodyTx"
            />
          </div>
          <div class="d-flex justify-content-center py-8" @click="onScnd">
            <button-common
              v-if="G_MODAL_INFO.btnScndNm"
              :size="SIZE_T"
              :clr="CLR_W"
              @click="onScnd"
            >
              {{ G_MODAL_INFO.btnScndNm }}
            </button-common>
          </div>
        </div>
      </div>

      <!--* Confirm -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_CONFIRM" :class="wrapCls">
        <div :class="`modal-content p-32 ${G_MODAL_INFO.size}`">
          <div class="py-16 w-100 text-center">
            <span
              :class="`${G_MODAL_INFO.bodyCls} fr-h6 f-g80`"
              v-html="G_MODAL_INFO.bodyTx"
            />
          </div>
          <div class="d-flex justify-content-center py-8" @click="onScnd">
            <button-common
              :size="G_MODAL_INFO.btnSize"
              :clr="CLR_W"
              :wp-cls="`mr-8`"
              @click="onScnd"
            >
              {{ G_MODAL_INFO.btnScndNm }}
            </button-common>
            <button-common
              :clr="CLR_M"
              :size="G_MODAL_INFO.btnSize"
              @click="onPrmy"
            >
              {{ G_MODAL_INFO.btnPrmyNm }}
            </button-common>
          </div>
        </div>
      </div>

      <!--* INFO -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_INFO" :class="wrapCls">
        <div :class="`modal-content p-32 ${G_MODAL_INFO.size}`">
          <div class="py-16 w-100 text-center">
            <span :class="`${G_MODAL_INFO.bodyCls} fr-h6 f-g80`">{{
              G_MODAL_INFO.bodyTx
            }}</span>
          </div>
          <div class="d-flex justify-content-center py-8" @click="onScnd">
            <button-common
              :size="G_MODAL_INFO.btnSize"
              :wp-cls="`${G_MODAL_INFO.btnScndCls} mr-8`"
              :clr="G_MODAL_INFO.btnScndClr"
              @click="onScnd"
            >
              닫기
            </button-common>
          </div>
        </div>
      </div>

      <!--* Prompt -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_PROMPT" :class="wrapCls">
        <div :class="`modal-content ${G_MODAL_INFO.size}`">
          <div class="modal-header d-flex space-between p-16">
            <div class="fb-16">{{ G_MODAL_INFO.headTx }}</div>
            <div class="modal-exit" @click="onScnd"></div>
          </div>
          <div class="p-32 w-100">
            <div class="d-flex w-100 space-between align-items-center">
              <div class="fm-14 f-g70 change-folder-name">
                {{ G_MODAL_INFO.bodyTx }}
              </div>
              <input-text
                v-model="inputValue"
                :use-counter="G_MODAL_INFO.useCounter"
                :limit-nm="G_MODAL_INFO.limitNm"
                :holder-nm="holderNm"
                wp-cls="w-100"
                wp-cls2="my-8"
                ref="inputRef"
              />
            </div>
          </div>
          <div class="modal-button d-flex flex-end p-24">
            <button-common
              :size="G_MODAL_INFO.btnSize"
              :wp-cls="`${G_MODAL_INFO.btnScndCls} mr-8`"
              :clr="G_MODAL_INFO.btnScndClr"
              @click="onScnd"
            >
              {{ G_MODAL_INFO.btnScndNm }}
            </button-common>
            <!-- <button-common
              :wp-cls="G_MODAL_INFO.btnPrmyCls"
              :size="G_MODAL_INFO.btnSize"
              :clr="G_MODAL_INFO.btnPrmyClr"
              @click="onPrmy"
            >
              {{ G_MODAL_INFO.btnPrmyNm }}
            </button-common> -->
          </div>
        </div>
      </div>

      <!--* VueDaumPostCode -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_ADDRESS" :class="wrapCls">
        <div class="max-hei d-flex">
          <vue-daum-postcode @complete="searchAddress" />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalBasicInfo -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_BASIC_INFO" :class="wrapCls">
        <div class="max-hei d-flex" style="width: 60vw">
          <modal-basic-info />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalBusinessInfo -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_BUSINESS_INFO" :class="wrapCls">
        <div class="max-hei d-flex" style="width: 60vw">
          <modal-business-info />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalBankInfo -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_BANK_INFO" :class="wrapCls">
        <div class="max-hei d-flex" style="width: 60vw">
          <modal-bank-info />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* REJECT REASON -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_REJECT_REASON" :class="wrapCls">
        <div style="width: 600px; background: white">
          <modal-reject-reason />
        </div>
      </div>

      <!--* COMMISSION -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_COMMISSION" :class="wrapCls">
        <div style="width: 600px; background: white">
          <modal-commission />
        </div>
      </div>

      <!--* ModalMemberCode -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_MEMBER_CODE" :class="wrapCls">
        <div class="d-flex" style="width: 600px">
          <modal-member-code />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalPartenerCode -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_PARTENER_CODE" :class="wrapCls">
        <div class="max-hei d-flex" style="width: 60vw">
          <modal-partener-code />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalPaymentType -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_PAYMENT_TYPE" :class="wrapCls">
        <div class="d-flex" style="width: 600px">
          <modal-payment-type />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalPaymentGroup -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_PAYMENT_GROUP" :class="wrapCls">
        <div class="d-flex" style="width: 600px">
          <modal-payment-group />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalPaymentNumber -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_PAYMENT_NUMBER" :class="wrapCls">
        <div class="d-flex" style="width: 600px">
          <modal-payment-number />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalPaymentCancel -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_PAYMENT_CANCEL" :class="wrapCls">
        <div class="d-flex" style="width: 600px">
          <modal-payment-cancel />
        </div>
      </div>

      <!--* ModalPaymentCalculate -->
      <div
        v-if="G_MODAL_INFO.uiType === TYPE_PAYMENT_CALCULATE"
        :class="wrapCls"
      >
        <div class="d-flex" style="max-height: 50vh">
          <modal-payment-calculate
            style="width: 1200px; background: #fff; padding: 32px 16px"
          />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* ModalDuplicate -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_DUPLICATE" :class="wrapCls">
        <div style="width: 400px; background: white">
          <modal-duplicate />
        </div>
      </div>

      <!--* ModalEmp -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_EMP" :class="wrapCls">
        <div style="width: 400px; background: white">
          <modal-emp />
        </div>
      </div>

      <!--* ModalError -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_ERROR" :class="wrapCls">
        <div style="width: 400px; background: white">
          <modal-error />
        </div>
      </div>

      <!--* APPINFO -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_APPINFO" :class="wrapCls">
        <div style="width: 600px; background: white">
          <modal-app-info />
        </div>
      </div>

      <!--* ModalReceipt -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_RECEIPT" :class="wrapCls">
         <div class="d-flex" style="width: 500px; height: 80vh">
          <modal-receipt />
          <div class="close-wrap ml-8" @click="onClosePreviewModal">
            <img src="@/assets/image/close.svg" />
          </div>
        </div>
      </div>

      <!--* Alarm -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_ALARM" :class="wrapCls">
        <div style="width: 600px; background: white">
          <modal-alarm />
        </div>
      </div>

      <!--* AGREE -->
      <div v-if="G_MODAL_INFO.uiType === TYPE_AGREE" :class="wrapCls">
        <div style="width: 600px; background: white">
          <modal-agree />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mixinForm from "@/mixins/mixinForm";
import ModalBasicInfo from "@/components/ui/modal/ModalBasicInfo";
import ModalBusinessInfo from "@/components/ui/modal/ModalBusinessInfo";
import ModalBankInfo from "@/components/ui/modal/ModalBankInfo";
import ModalRejectReason from "@/components/ui/modal/ModalRejectReason";
import ModalCommission from "@/components/ui/modal/ModalCommission";
import ModalPartenerCode from "@/components/ui/modal/ModalPartenerCode";
import ModalMemberCode from "@/components/ui/modal/ModalMemberCode";
import ModalPaymentType from "@/components/ui/modal/ModalPaymentType";
import ModalPaymentGroup from "@/components/ui/modal/ModalPaymentGroup";
import ModalPaymentNumber from "@/components/ui/modal/ModalPaymentNumber";
import ModalPaymentCancel from "@/components/ui/modal/ModalPaymentCancel";
import ModalPaymentCalculate from "@/components/ui/modal/ModalPaymentCalculate";
import ModalDuplicate from "@/components/ui/modal/ModalDuplicate";
import ModalEmp from "@/components/ui/modal/ModalEmp";
import ModalError from "@/components/ui/modal/ModalError";
import ModalAppInfo from "@/components/ui/modal/ModalAppInfo";
import ModalReceipt from "@/components/ui/modal/ModalReceipt";
import ModalAlarm from "@/components/ui/modal/ModalAlarm";
import ModalAgree from "@/components/ui/modal/ModalAgree";
import { mapGetters } from "vuex";

export default {
  name: "ModalCommon",

  /*** components ***/
  components: {
    ModalBasicInfo,
    ModalBusinessInfo,
    ModalBankInfo,
    ModalRejectReason,
    ModalCommission,
    ModalPartenerCode,
    ModalMemberCode,
    ModalPaymentType,
    ModalPaymentGroup,
    ModalPaymentNumber,
    ModalPaymentCancel,
    ModalPaymentCalculate,
    ModalDuplicate,
    ModalEmp,
    ModalError,
    ModalAppInfo,
    ModalReceipt,
    ModalAlarm,
    ModalAgree,
  },

  /*** mixins ***/
  mixins: [mixinForm],

  /*** data ***/
  data() {
    return {
      ntDt: {},
      inputValue: "",
      propAlert: false,
      folderEsntlId: "",
      selectTask: null,
      selectCourse: null,
      isWrite: false,
      feedType: "",
      esntlId: "",
    };
  },

  /*** created ***/
  created() {},

  /*** mounted ***/
  mounted() {
    this.$store.dispatch("A_MODAL_TOGGLE", false);
  },

  /*** unmounted ***/
  unmounted() {
    this.$store.dispatch("A_MODAL_TOGGLE", false);
  },

  /*** computed ***/
  computed: {
    ...mapGetters([
      "G_MODAL_TOGGLE",
      "G_MODAL_INFO",
      "G_MODAL_RESET",
      "G_FOLDER_ID",
    ]),
    wrapCls() {
      let cls = "";
      cls +=
        "black-bg d-flex column justify-content-center align-items-center ";
      cls += this.wpCls ? this.wpCls : "";
      return cls;
    },
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) document.documentElement.style.overflow = "hidden";
      else document.documentElement.style.overflow = "auto";
    },
    G_MODAL_INFO: {
      deep: true,
      handler(val) {
        this.inputValue = val.defTx || "";
      },
    },
  },

  /*** methods ***/
  methods: {
    /**@ Prmy CB ***/
    onPrmy() {
      if (
        this.G_MODAL_INFO.uiType === this.TYPE_PROMPT &&
        this.inputValue === ""
      ) {
        this.$refs.inputRef.$refs.inputRef.focus();
      } else if (
        (this.G_MODAL_INFO.uiType === this.TYPE_TASK_MOVE ||
          this.G_MODAL_INFO.uiType === this.TYPE_COURSE_MOVE) &&
        this.folderEsntlId !== ""
      ) {
        this.G_MODAL_INFO.cbPrmy(this.folderEsntlId);
        this.$store.dispatch("A_MODAL_TOGGLE", false);
      } else if (
        this.G_MODAL_INFO.uiType === this.TYPE_LOAD_TASK &&
        this.selectTask
      ) {
        this.G_MODAL_INFO.cbPrmy(this.selectTask);
        this.$store.dispatch("A_MODAL_TOGGLE", false);
      } else if (
        this.G_MODAL_INFO.uiType === this.TYPE_LOAD_COURSE &&
        this.selectCourse
      ) {
        this.G_MODAL_INFO.cbPrmy(this.selectCourse);
        this.$store.dispatch("A_MODAL_TOGGLE", false);
      } else if (
        this.G_MODAL_INFO.uiType === this.TYPE_PROMPT &&
        this.inputValue !== ""
      ) {
        this.G_MODAL_INFO.cbPrmy(this.inputValue);
        this.$store.dispatch("A_MODAL_TOGGLE", false);
      } else {
        this.G_MODAL_INFO.cbPrmy(this.G_MODAL_INFO.data);
        this.$store.dispatch("A_MODAL_TOGGLE", false);
      }
    },

    /**@ Scnd CB ***/
    onScnd(e) {
      if (
        this.G_MODAL_INFO.uiType === this.TYPE_TASK_MOVE ||
        this.G_MODAL_INFO.uiType === this.TYPE_COURSE_MOVE
      ) {
        this.$store.dispatch("FOLDER2/A_FOLDER_RESET");
        this.$store.dispatch("PAGER2/A_PAGER_RESET");
      } else if (
        this.G_MODAL_INFO.uiType === this.TYPE_ALERT &&
        this.G_MODAL_INFO.cbScnd
      ) {
        this.G_MODAL_INFO.cbScnd(this.G_MODAL_INFO.data);
      }
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },

    /**@ TYPE_TASK_MOVE ***/
    onChangeFolderEsntlId(val) {
      this.folderEsntlId = val;
    },

    /**@ TYPE_LOAD_TASK ***/
    onSelectTask(val) {
      this.selectTask = val;
    },

    /**@ TYPE_LOAD_COURSE ***/
    onSelectCourse(val) {
      this.selectCourse = val;
    },

    searchAddress(address) {
      this.G_MODAL_INFO.cbPrmy(address.roadAddress);
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },

    onClosePreviewModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
      this.$store.dispatch("A_PREVIEW_RESET");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-content {
  width: 800px;
  background: var(--white);
}

.modal-content.small {
  width: 600px;
}

.modal-content.tiny {
  width: 400px;
}

.max-hei {
  max-height: 80vh;
  overflow: auto;
}

.close-wrap {
  width: 22px;
  height: 22px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
