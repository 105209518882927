import _ from 'lodash'
export default {
  G_PREVIEW_COURSE_NAME: (state) => {
    return state.COURSE_NAME
  },
  G_PREVIEW_TASK_NAME: (state) => {
    return state.TASK_NAME
  },
  G_PREVIEW_TASK_TITLE: (state) => {
    return state.TASK_TITLE
  },
  G_PREVIEW_TASK_ID: (state) => {
    return state.TASK_ID
  },
  G_PREVIEW_CUSTOMER_TASK_ID: (state) => {
    return state.CUSTOMER_TASK_ID
  },
  G_PREVIEW_COURSE_ID: (state) => {
    return state.COURSE_ID
  },
  G_PREVIEW_TASK_DAY: (state) => {
    return state.TASK_DAY
  },
  G_PREVIEW_TASK_INDEX: (state) => {
    return state.TASK_INDEX
  },
  G_PREVIEW_FEED_TYPE: (state) => {
    return state.FEED_TYPE
  },
  G_PREVIEW_USER_NAME: (state) => {
    return state.USER_NAME
  },
}
