const A_MEMBER_CODE = ({ commit }, payload) => {
  commit("M_MEMBER_CODE", payload);
};
const A_PARTENER_CODE = ({ commit }, payload) => {
  commit("M_PARTENER_CODE", payload);
};
const A_PAYMENT_DATA = ({ commit }, payload) => {
  commit("M_PAYMENT_DATA", payload);
};
const A_IS_CONFIRM = ({ commit }, payload) => {
  commit("M_IS_CONFIRM", payload);
};
const A_ERROR_CODE = ({ commit }, payload) => {
  commit("M_ERROR_CODE", payload);
};
const A_AGREE_CODE = ({ commit }, payload) => {
  commit("M_AGREE_CODE", payload);
};

export default {
  A_MEMBER_CODE,
  A_PARTENER_CODE,
  A_PAYMENT_DATA,
  A_IS_CONFIRM,
  A_ERROR_CODE,
  A_AGREE_CODE,
};
