<template>
  <div class="aside">
    <avatar v-if="isAvatar" />
    <nav-wrap />
  </div>
</template>

<script>
import NavWrap from '@/components/common/aside-wrapper/NavWrap'
import Avatar from '@/components/common/aside-wrapper/Avatar'

export default {
  name: 'AsideWrapper',
  components: { Avatar, NavWrap },
  data() {
    return {
      isAvatar: false,
    };
  },
  mounted() {
    this.isAvatar = localStorage.getItem('auth') === 'COM'
  },
}
</script>

<style scoped lang="scss">
  .aside {
    flex-shrink: 0;
  }
</style>
