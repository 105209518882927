<template>
  <div class="upload-img-box d-flex" :value="value">
    <div
      class="file-box d-flex column justify-content-center align-items-center"
    >
      <icon-media v-if="!src" :type-nm="typeNm">{{ lblNm }}</icon-media>
      <preview-image v-if="src" :src-nm="src" :size-nm="100" />
      <div v-if="src" class="fm-12 pt-4">{{ name }}</div>
      <input
        accept="image/*"
        type="file"
        class="d-none"
        ref="fileRef"
        @input="handleUpload"
      />
      <div class="d-flex justify-content-center align-items-center">
        <button-common
          v-if="!src"
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mt-24"
          @click="$refs.fileRef.click()"
          >업로드</button-common
        >
        <button-common
          v-if="src"
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mt-24 mr-8"
          @click="$refs.fileRef.click()"
          >수정</button-common
        >
        <button-common
          v-if="src"
          :size="SIZE_T"
          :clr="CLR_S"
          wp-cls="mt-24"
          @click="deleteImage"
          >삭제</button-common
        >
      </div>
    </div>
    <text-upload
      class="cont-box"
      v-model="description"
      wp-cls="upload-img-box-input"
      :use-counter="true"
      :holder-nm="holderNm"
      @input="handleChange(file, src, $event)"
      v-if="showDescription"
    />
  </div>
</template>

<script>
import PreviewImage from "@/components/ui/content/PreviewImage";
import mixinForm from "@/mixins/mixinForm";

export default {
  name: "UploaderBoxImg",
  components: { PreviewImage },
  mixins: [mixinForm],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    fileName: {
      type: String,
      default: "",
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      file: null,
      src: "",
      name: "",
      description: "",
    };
  },
  computed: {},
  /*** watch ***/
  watch: {
    value: {
      deep: true,
      handler() {
        this.file = this.value.file ? this.value.file : null;
        this.src = this.value.src ? this.value.src : "";
        this.description = this.value.description ? this.value.description : "";
        if (Array.isArray(this.file)) {
          this.name = " ";
        }
      },
    },
    fileName: {
      handler() {
        this.name = this.fileName ? this.fileName : "";
      },
    },
  },
  methods: {
    handleUpload(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0];
        this.src = URL.createObjectURL(e.target.files[0]);
        this.name = e.target.files[0].name;
        this.handleChange(this.file, this.src, this.value.description);
      }
    },
    handleChange(file, src, description) {
      this.$emit("input", { file: file, src: src, description: description });
    },
    deleteImage() {
      this.src = "";
      this.name = "";
      this.handleChange(null, "", "");
    },
  },
};
</script>

<style scoped lang="scss">
.file-box,
.cont-box {
  max-width: 320px;
}
</style>
