<template>
  <div class="w-100" style="padding: 32px 0 24px 0">
    <p clsss="duplicate-title" style="text-align: center">
      이미 동일한 개인정보로 가입된 계정이 있습니다.
    </p>
    <p class="duplicate-content" style="margin-top: 10px">
      기존 가입된 계정으로 로그인 해주세요.
    </p>
    <p class="duplicate-content">
      가입된 계정이 생각나지 않는다면 아이디 찾기를 통해 찾을 수 있습니다.
    </p>
    <div class="flex-justify-center" style="margin-top: 20px">
      <button-common :size="SIZE_T" :clr="CLR_W" @click="closeModal"
        >확인</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_M"
        style="width: 100px; margin-left: 16px"
        @click="goFindID"
        >아이디 찾기</button-common
      >
    </div>
    <p class="duplicate-link" style="margin-top: 20px" @click="goLoginPage">
      로그인페이지 가기
    </p>
  </div>
</template>

<script>
export default {
  name: "ModalDuplicate",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {};
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    closeModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
    goLoginPage() {
      this.closeModal();
      this.$router.push({
        path: `/login`,
        meta: { id: "0-1-1" },
      });
    },
    goFindID() {
      this.closeModal();
      this.$router.push({
        path: `/sign/find-id`,
        meta: { id: "0-1-12" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.duplicate-title {
  font-size: 16px;
  color: #12131a;
}
.duplicate-content {
  font-size: 12px;
  font-weight: 500;
  color: #828699;
  text-align: center;
}
.duplicate-link {
  font-size: 12px;
  font-weight: 500;
  color: #515466;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
</style>
