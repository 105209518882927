import _ from 'lodash'
export default {
  M_ALARM_DATA (state, data) {
    state.ALARM_DATA = data
  },
  M_IS_MEMBER (state, isMember) {
    state.IS_MEMBER = isMember
  },
  M_SHOW_BELL (state, showBell) {
    state.SHOW_BELL = showBell
  },
  M_BELL_COUNT (state, data) {
    state.BELL_COUNT = data
  },
}
