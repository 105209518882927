<template>
  <header class="header">
    <logo-wrap />
    <user-wrap />
    <bell-wrap v-if="isCom && showBell" v-click-outside="closeBell" />
  </header>
</template>

<script>
import ClickOutside from "vue-click-outside";
import LogoWrap from "@/components/common/header-wrapper/LogoWrap";
import UserWrap from "@/components/common/header-wrapper/UserWrap";
import BellWrap from "@/components/common/header-wrapper/BellWrap";
import { mapGetters } from "vuex";

export default {
  name: "HeaderWrapper",
  components: { UserWrap, LogoWrap, BellWrap },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showBell: false,
      isCom: false,
      isFirst: true,
    };
  },
  /*** computed ***/
  computed: {
    ...mapGetters(["G_SHOW_BELL"]),
  },
  /*** watch ***/
  watch: {
    G_SHOW_BELL() {
      this.showBell = this["G_SHOW_BELL"];
    },
  },
  mounted() {
    this.isCom = localStorage.getItem("auth") === "COM";
  },
  methods: {
    clickBell() {
      this.showBell = !this.showBell;
    },
    closeBell() {
      if(this.isFirst) {
        this.isFirst = false;
      } else {
        this.isFirst = true;
        this.$store.dispatch("A_SHOW_BELL", false);
      }  
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  flex-grow: 0;
}
</style>
