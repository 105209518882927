<template>
  <div class="w-100">
    <div class="commission-header-wrap flex-space-between flex-align-center">
      <span>{{ title }}</span>
      <img
        src="@/assets/image/close.svg"
        style="cursor: pointer"
        @click="closeModal"
      />
    </div>
    <div
      class="w-100"
      ref="content"
      style="padding: 20px; height: 520px; overflow: auto"
      v-html="content"
    ></div>
    <div class="commission-bottom-wrap">
      <button-common :size="SIZE_T" :clr="CLR_M" @click="doAgree"
        >동의</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_W"
        wp-cls="mr-8"
        @click="closeModal"
        >닫기</button-common
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalAgree",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      title: "",
      content: "",
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_AGREE_CODE"]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.$refs.content.scrollTop = 0;
        this.getData();
      }
    },
  },

  /*** methods ***/
  methods: {
    async getData() {
      const rs = await this.$axios({
        url: "/admin/system/partner/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.setData(rs.resultData);
      } else {
        console.log("약관 조회 실패");
      }
    },
    setData(resultData) {
      switch (this["G_AGREE_CODE"]) {
        case "TERM":
          this.title = "서비스 이용약관";
          this.content = resultData.partnerTermOfService;
          break;
        case "PRIVACY":
          this.title = "개인정보 수집 및 이용동의";
          this.content = resultData.partnerCollectionOfInfo;
          break;
        case "ELECTRONIC":
          this.title = "전자금융거래 이용약관";
          this.content = resultData.electronicFinancialTransaction;
          break;
        default:
          this.title = "제휴 마케팅 동의";
          this.content = resultData.partnerMaketting;
          break;
      }
    },
    doAgree() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
    closeModal() {
      this.$store.dispatch("A_AGREE_CODE", "");
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
  },
};
</script>

<style scoped lang="scss">
.commission-header-wrap {
  background: #f8f8fb;
  padding: 16px;
}
.commission-content-wrap {
  padding: 16px;
  display: flex;
  align-items: center;
}
.commission-category {
  color: #828699;
  font-weight: 500;
  min-width: 150px;
}
.commission-bottom-wrap {
  background: #f8f8fb;
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
