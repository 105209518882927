import { render, staticRenderFns } from "./ModalBasicInfo.vue?vue&type=template&id=798f51ee&scoped=true&"
import script from "./ModalBasicInfo.vue?vue&type=script&lang=js&"
export * from "./ModalBasicInfo.vue?vue&type=script&lang=js&"
import style0 from "./ModalBasicInfo.vue?vue&type=style&index=0&id=798f51ee&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "798f51ee",
  null
  
)

export default component.exports