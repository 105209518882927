<template>
  <ul class="menu">
    <!-- <nav-all /> -->
    <nav-main v-for="v in navList" :key="v.id" :menu="v" />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
// import NavAll from '@/components/common/aside-wrapper/NavAll'
import NavMain from '@/components/common/aside-wrapper/NavMain'


export default {
  name: 'NavWrap',
  data() {
    return {
      auth: '',
      adminAuthList: [],
      navList: []
    }
  },
  components: { NavMain },
  computed: {
    ...mapGetters(['G_NAV_ALL'])
  },
  mounted() {
    this.auth = localStorage.getItem("auth");
    this.navList = JSON.parse(JSON.stringify(this.G_NAV_ALL)).filter((nav) => nav.auth.includes(this.auth))
    if(this.auth === 'ADM') {
      this.adminAuthList = localStorage.getItem("authList").split(',');
      this.navList.forEach(item => {
        item.sub = item.sub.filter(data => this.adminAuthList.includes(data.auth))
      })
      this.navList = this.navList.filter(item => item.sub.length > 0)
    }
  },
}
</script>

<style scoped lang="scss">

</style>
