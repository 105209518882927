const A_COMMISSION_IS = ({ commit }, payload) => {
  commit("M_COMMISSION_IS", payload);
};
const A_COMMISSION_DATA = ({ commit }, payload) => {
  commit("M_COMMISSION_DATA", payload);
};

export default {
  A_COMMISSION_IS,
  A_COMMISSION_DATA,
};
