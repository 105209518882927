<template>
  <!-- 기본정보 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between">
      <div class="flex-align-center">
        <span class="header-category">기본정보</span>
      </div>
    </div>
    <!-- 이름 -->
    <div class="info-content-wrap">
      <span class="content-category">이름</span>
      <span>{{ infoData.name }} {{ infoData.gender }}</span>
    </div>
    <!-- 로그인 ID -->
    <div class="info-content-wrap">
      <span class="content-category">로그인 ID</span>
      <span>{{ infoData.email }}</span>
    </div>
    <!-- 생년월일 -->
    <div class="info-content-wrap">
      <span class="content-category">생년월일</span>
      <span>{{ infoData.birth }}</span>
    </div>
    <!-- 휴대폰번호 -->
    <div class="info-content-wrap">
      <span class="content-category">휴대폰번호</span>
      <span v-if="infoData.phone">{{ formatPhone(infoData.phone) }}</span>
    </div>
    <!-- 거주지역 -->
    <div class="info-content-wrap">
      <span class="content-category">거주지역</span>
      <span>{{ infoData.addr }} {{ infoData.addr2 }}</span>
    </div>
    <!-- 분야 -->
    <div class="info-content-wrap">
      <span class="content-category">분야</span>
      <span
        >{{ infoData.highSpecialtyTitle }} > {{ infoData.lowSpecialtyTitle }} >
        {{ infoData.orderSpecialty ? infoData.orderSpecialty : "없음" }}</span
      >
    </div>
    <!-- 경력 -->
    <div class="info-content-wrap">
      <span class="content-category">경력</span>
      <span>{{ infoData.career }}</span>
    </div>
    <!-- 자격증명 URL -->
    <div class="info-content-wrap">
      <span class="content-category">자격증명 URL</span>
      <span style="text-decoration: underline">{{ infoData.proofUrl }}</span>
    </div>
    <!-- 자격증명 파일 -->
    <div class="info-content-wrap">
      <span class="content-category">자격증명 파일</span>
      <span style="text-decoration: underline">{{
        infoData.proofFileOri
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalBasicInfo",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      infoData: null,
    };
  },

  /*** mounted ***/
  mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE"]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.getBasicData();
      }
    },
  },

  /*** methods ***/
  methods: {
    async getBasicData() {
      const rs = await this.$axios({
        url: "/partner/myInfo/basic/data",
        params: {},
      });
      if (rs.result === "SUC") {
        if (rs.resultData.infoData) {
          if (rs.resultData.infoData.length > 0) {
            this.infoData = rs.resultData.infoData[0];
          }
        }
        this.setEditData(rs.resultData.editData);
      } else {
        console.log("기본정보 조회 실패");
      }
    },
    setEditData(editData) {
      Object.entries(editData).forEach((item) => {
        if (item[1]) {
          this.infoData[item[0]] = item[1];
        }
      });
      if(editData.highSpecialtyEsntlId === "") {
        this.infoData.highSpecialtyTitle = "없음"
      }
      if(editData.lowSpecialtyEsntlId === "") {
        this.infoData.lowSpecialtyTitle = "없음"
      }
      if(editData.orderSpecialty === "") {
        this.infoData.orderSpecialty = "없음"
      }
    },
    formatPhone(num) {
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
</style>
