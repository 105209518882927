import _ from 'lodash'
export default {
  M_MEMBER_CODE(state, code) {
    state.MEMBER_CODE = code
  },
  M_PARTENER_CODE(state, code) {
    state.PARTENER_CODE = code
  },
  M_PAYMENT_DATA(state, data) {
    state.PAYMENT_DATA = data
  },
  M_IS_CONFIRM(state, isConfirm) {
    state.IS_CONFIRM = isConfirm
  },
  M_ERROR_CODE(state, code) {
    state.ERROR_CODE = code
  },
  M_AGREE_CODE(state, code) {
    state.AGREE_CODE = code
  },
}
