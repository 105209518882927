<template>
  <div :value="value" class="fixed d-flex align-items-center flex-end under-finish">
    <div class="warning fr-12 mr-32">
      <slot />
    </div>
    <div>
      <button-common :size="SIZE_T" :clr="CLR_W" wp-cls="mr-8" @click="$emit('change', 'PREVIEW')" v-if="isPreview">미리보기</button-common>
      <button-common :size="SIZE_T" :clr="CLR_W" wp-cls="mr-8" @click="$emit('change', 'DELETE')" v-if="isDelete">삭제하기</button-common>
      <button-common :size="SIZE_T" :clr="CLR_W" wp-cls="mr-8" @click="$emit('change', 'RESET')">취소</button-common>
      <button-common :size="SIZE_T" :clr="CLR_M" @click="$emit('change', 'SAVE')">저장하기</button-common>
    </div>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'BottomInfo',

  /*** mixins ***/
  mixins: [mixinForm],

  /*** components ***/
  components: {  },

  /*** props ***/
  props: {
    value: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: true
    },
    isDelete: {
      type: Boolean,
      default: false
    },
  },

  /*** data ***/
  data() {
    return {

    }
  },

  /*** created ***/
  created() {

  },

  /*** computed ***/
  computed: {

  },

  /*** watch ***/
  watch: {

  },

  /*** methods ***/
  methods: {

  },
}
</script>

<style scoped lang="scss">

</style>


