export default {
  G_MODAL_TOGGLE: state => {
    return state.MODAL_TOGGLE
  },
  G_MODAL_INFO: state => {
    return state.MODAL_INFO
  },
  G_MODAL_RESET: state => {
    return {
      uiType: '', // ALERT, PROMPT, CONFIRM
      cbPrmy: '', // 값바뀔때 콜백
      cbScnd: '', // 닫을때 콜백
      defVal: '', // PROMPT에서 기본값
      size: '', // tiny, small, large
      headCls: '',  // head class
      bodyCls: '',  // body class
      footCls: '',  // footer class
      headTx: '', // head text
      bodyTx: '', // body text
      footTx: '', // foot text
      btnSize: 'tiny',  // btn size tiny, small, medium, large, full
      btnPrmyNm: '확인',  // prmy button text
      btnScndNm: '취소',  // scnd button text
      btnPrmyClr: 'm', // prmy button color w, s, m
      btnScndClr: 'w', // scnd button text w, s, m
      btnPrmyCls: '', // prmy button class
      btnScndCls: '', // scnd button class
      useCounter: false,
      limitNm: 0,
      
      data: {},
    }
  },
}
