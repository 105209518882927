<template>
  <li>
    <router-link :to="{ path: subMenu.path }" :class="(selected ? 'selected' : '')">{{ subMenu.title }}</router-link>
  </li>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'NavSub',
  props: ['subMenu'],
  computed: {
    ...mapGetters(['G_NAV_PARENTS', 'G_NAV_ID']),
    selected() {
      return _.some(this.G_NAV_PARENTS, { id: this.subMenu.id })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
