<template>
  <!-- 결제그룹번호 -->
  <div class="info-container">
    <div class="info-header-wrap">
      <span class="header-category">자동결제 신청내역</span>
    </div>
    <!-- 결제자명 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">결제자명</span>
      <span class="content-bold">{{ infoData.customerName }}</span>
    </div>
    <!-- 신청일 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">신청일</span>
      <span class="content-bold">{{ infoData.regDate }}</span>
    </div>
    <!-- 결제항목 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">결제항목</span>
      <span class="content-bold">{{ infoData.goodName }}</span>
    </div>
    <!-- 항목번호 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">항목번호</span>
      <span class="content-bold">{{ infoData.serviceEsntlId }}</span>
    </div>
    <!-- 자동결제 기간 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">자동결제 기간</span>
      <span class="content-bold">{{ formatDate(infoData.startDate) }} ~ {{ formatDate(infoData.endDate) }}[{{ calDate(infoData.startDate, infoData.endDate) }}개월]</span>
    </div>
    <!-- 자동결제 금액 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">자동결제 금액</span>
      <span class="content-bold">{{ formatPrice(infoData.price) }}원</span>
    </div>
    <!-- 결제수단 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">결제수단</span>
      <span class="content-bold">{{ infoData.card }}({{ infoData.cardNumber }})</span>
    </div>
    <!-- 결제일 -->
    <div class="info-content-wrap" v-if="infoData">
      <span class="content-category">결제일</span>
      <span class="content-bold">{{ infoData.paymentDay }}일</span>
    </div>
    <!-- 서명 -->
    <div class="info-header-wrap" style="margin-top: 24px">
      <span class="header-category">결제동의 서명</span>
    </div>
    <div class="info-sign" v-if="infoData">
      <div class="info-sign-wrap">
        <img :src="getImage(infoData.signImage)" style="width: 50%"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalPaymentGroup",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      esntlId: "",
      infoData: null,
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_MEMBER_CODE"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.esntlId = this["G_MEMBER_CODE"];
        this.getPaymentGroupNumber();
      } else {
        this.esntlId = "";
        this.infoData = null;
      }
    },
  },

  /*** methods ***/
  methods: {
    async getPaymentGroupNumber() {
      const rs = await this.$axios({
        url: "/admin/popup/group",
        params: { esntlId: this.esntlId },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData;
      } else {
        console.log("결제 그룹 번호 상세 조회 실패");
      }
    },

    getImage(image) {
      return (
        "https://medal.sailors.kr/upload/user/" +
        image
      );
    },

    formatDate(date) {
      return this.$moment(date).format('YYYY.MM')
    },

    calDate(start, end) {
      const startDate = this.$moment(start);
      const endDate = this.$moment(end);
      return endDate.diff(startDate, 'months')
    },
 
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  padding: 22px;
}
.info-header-wrap {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  font-size: 20px;
  font-weight: 700;
}
.header-sub-category {
  color: #babdcc;
  font-size: 14px;
  font-weight: 700;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-category {
  color: #828699;
  min-width: 200px;
}
.content-bold {
  font-weight: 500;
  font-size: 14px;
}
.info-divider {
  width: 100%;
  height: 1px;
  background: #d8dae5;
}
.content-discount {
  min-width: 50px;
  text-align: right;
}
.info-sign {
  padding: 40px;
  width: 100%;
}
.info-sign-wrap {
  width: 100%;
  height: 100%;
  background: #F3F3F3;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
