<template>
  <div class="d-flex column justify-content-center align-items-center" @click="handleChangeCommon">
    <img :src="src">
    <span class="f-gray fr-14 mt-8">
      <slot />
    </span>
  </div>
</template>

<script>
/* *
* typeNm: IMG/IMG_P/DOC/DOC_P/MOVIE/MOVIE_P/CLIP/CLIP_P/FILE/FILE_P/LINK/LINK_P/MISSION
*  */
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'IconMedia',
  mixins: [mixinForm],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {
    src() {
      let src = '/image/icon/30px/'
      switch (this.typeNm) {
        case 'IMG':
          src = src + 'image.png'
          break
        case 'IMG_P':
          src = src + 'plus-image.png'
          break
        case 'TEXT':
          src = src + 'text.png'
          break
        case 'TEXT_P':
          src = src + 'plus-text.png'
          break
        case 'VIDEO':
          src = src + 'video.png'
          break
        case 'VIDEO_P':
          src = src + 'plus-video.png'
          break
        case 'YOUTUBE':
          src = src + 'youtube.png'
          break
        case 'YOUTUBE_P':
          src = src + 'plus-youtube.png'
          break
        case 'FILE':
          src = src + 'file.png'
          break
        case 'FILE_P':
          src = src + 'plus-file.png'
          break
        case 'LINK':
          src = src + 'link.png'
          break
        case 'LINK_P':
          src = src + 'plus-link.png'
          break
        case 'MISSION':
          src = src + 'mission.png'
          break
        default:
          src = 'https://via.placeholder.com/30'
          break
      }
      return src
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
