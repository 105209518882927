<template>
  <div class="pagination d-flex align-items-center justify-content-center">
    <button @click="handlePrev">
      <svg-arrow-prev />
    </button>
    <div class="page-list d-flex">
      <a
          v-for="val in pager"
          :key="val"
          :title="val"
          :class="`page-number ${val === G_PAGE ? 'f-main' : ''}`"
          @click="handlePage"
      >{{ val }}</a>
    </div>
    <button @click="handleNext">
      <svg-arrow-next />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Pagination',

  /*** data ***/
  data() {
    return {
      totPage: Math.ceil(this.G_TOTCNT / this.G_LSTCNT),
      prevPage: 0,
      nextPage: 0,
      stPage: 0,
      edPage: 0,
    }
  },

  /*** created ***/
  created() {
    this.changeData()
  },

  /*** computed ***/
  computed: {
    ...mapGetters(['G_PAGE', 'G_LSTCNT', 'G_PGCNT', 'G_TOTCNT']),
    pager: {
      get() {
        const pages = [];
        for(let i=this.stPage; i<=this.edPage; i++) pages.push(i)
        return pages
      },
      set(val) {
        console.log(val)
      }
    }
  },

  /*** watch ***/
  watch: {
    G_PAGE: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
    G_LSTCNT: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
    G_PGCNT: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
    G_TOTCNT: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
  },

  /*** methods ***/
  methods: {
    changeData() {
      this.totPage = Math.ceil(this.G_TOTCNT / this.G_LSTCNT)
      this.prevPage = this.G_PAGE > 1 ? this.G_PAGE - 1 : 1
      this.nextPage = this.G_PAGE < this.totPage ? this.G_PAGE + 1 : this.totPage
      this.stPage = Math.floor((this.G_PAGE - 1) / this.G_PGCNT) * this.G_PGCNT + 1
      this.edPage = this.stPage + this.G_PGCNT - 1 > this.totPage ? this.totPage : this.stPage + this.G_PGCNT - 1
      /* console.log('%c ==== Pagination: S', 'color: #0A6F7A')
      console.log('G_PAGE => ', this.G_PAGE)
      console.log('G_TOTCNT => ', this.G_TOTCNT)
      console.log('G_PGCNT => ', this.G_PGCNT)
      console.log('G_LSTCNT => ', this.G_LSTCNT)
      console.log('totPage => ', this.totPage)
      console.log('prevPage => ', this.prevPage)
      console.log('nextPage => ', this.nextPage)
      console.log('stPage => ', this.stPage)
      console.log('edPage => ', this.edPage)
      console.log('%c ==== Pagination: E', 'color: #0A6F7A') */
    },
    handlePrev(e) {
      this.$emit('click', this.prevPage)
    },
    handleNext(e) {
      this.$emit('click', this.nextPage)
    },
    handlePage(e) {
      this.$emit('click', e.target.title)
    }
  }
}
</script>

<style scoped>
button, a {
  cursor: pointer
}
</style>
