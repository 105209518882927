export default [
  {
    id: "0",
    title: "로그인",
    auth: ["COM", "ADM"],
    sub: [
      {
        id: "0-1",
        title: "로그인",
        redirect: "/login",
        path: "/",
        sub: [
          { id: "0-1-1", title: "로그인", name: "Login", path: "/login" },
          {
            id: "0-1-2",
            title: "파트너 가입 신청",
            name: "Apply",
            path: "/sign/apply",
          },
          { id: "0-1-3", title: "본인 인증", name: "Auth", path: "/sign/auth" },
          { id: "0-1-4", title: "이메일", name: "Email", path: "/sign/email" },
          {
            id: "0-1-5",
            title: "주소",
            name: "Address",
            path: "/sign/address",
          },
          {
            id: "0-1-6",
            title: "카테고리",
            name: "Category",
            path: "/sign/category",
          },
          {
            id: "0-1-7",
            title: "가입동의",
            name: "Agree",
            path: "/sign/agree",
          },
          {
            id: "0-1-8",
            title: "가입완료",
            name: "Finish",
            path: "/sign/finish",
          },
          {
            id: "0-1-9",
            title: "관리자로그인",
            name: "Login",
            path: "/operator",
          },
          {
            id: "0-1-10",
            title: "사업자 인증",
            name: "Business",
            path: "/sign/business",
          },
          {
            id: "0-1-11",
            title: "휴대폰 인증",
            name: "Phone",
            path: "/sign/phone",
          },
          {
            id: "0-1-12",
            title: "아이디 찾기",
            name: "FindID",
            path: "/sign/find-id",
          },
          {
            id: "0-1-13",
            title: "패스워드 찾기",
            name: "FindPW",
            path: "/sign/find-pw",
          },
        ],
      },
    ],
  },
  // 파트너
  {
    id: "1",
    title: "내정보",
    auth: ["COM"],
    sub: [
      {
        id: "1-1",
        title: "내정보",
        redirect: "/myInfo/company",
        path: "/myInfo",
        sub: [
          {
            id: "1-1-1",
            title: "파트너정보",
            name: "Company",
            path: "/myInfo/company",
          },
          {
            id: "1-1-2",
            title: "프로필",
            name: "Profile",
            path: "/myInfo/profile",
          },
        ],
      },
    ],
  },
  {
    id: "2",
    title: "결제등록",
    auth: ["COM"],
    sub: [
      {
        id: "2-1",
        title: "결제항목등록",
        redirect: "/payment/add",
        path: "/payment",
        sub: [
          {
            id: "2-1-1",
            title: "등록신청",
            name: "PaymentAdd",
            path: "/payment/add",
          },
          {
            id: "2-1-2",
            title: "등록현황",
            name: "PaymentStatus",
            path: "/payment/status",
          },
        ],
      },
      {
        id: "2-2",
        title: "지난등록내역",
        redirect: "/payment/prev",
        path: "/payment/prev",
        sub: [
          {
            id: "2-2-1",
            title: "지난내역",
            name: "PaymentPrev",
            path: "/payment/prev",
            sub: [
              {
                id: "2-2-1-1",
                title: "지난내역 상세",
                name: "PaymentPrevDetail",
                path: "/payment/prev/:id",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "3",
    title: "결제관리",
    auth: ["COM"],
    sub: [
      {
        id: "3-1",
        title: "결제 내역",
        redirect: "/payment/status/list",
        path: "/payment/status/list",
        sub: [
          {
            id: "3-1-1",
            title: "결제 현황",
            name: "PaymentStatusList",
            path: "/payment/status/list",
            sub: [],
          },
          {
            id: "3-1-2",
            title: "결제 오류 현황",
            name: "PaymentErrorList",
            path: "/payment/error/list",
            sub: [],
          },
        ],
      },
      {
        id: "3-2",
        title: "정산 내역",
        redirect: "/payment/calculate/list",
        path: "/payment/calculate/list",
        sub: [
          {
            id: "3-2-1",
            title: "기간별 정산내역",
            name: "PaymentCalculateList",
            path: "/payment/calculate/list",
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: "4",
    title: "계약내역",
    auth: ["COM"],
    sub: [
      {
        id: "4-1",
        title: "계약내역",
        redirect: "/contract/list",
        path: "/contract",
        sub: [
          {
            id: "4-1-1",
            title: "계약내역",
            name: "ContractList",
            path: "/contract/list",
            sub: [
              {
                id: "4-1-1-1",
                title: "계약내역 상세",
                name: "ContractDetail",
                path: "/contract/detail/:id",
              },
            ],
          },
        ],
      },
      {
        id: "4-2",
        title: "신규 계약신청",
        redirect: "/contract/reg",
        path: "/contract/reg",
        sub: [
          {
            id: "4-2-1",
            title: "계약신청",
            name: "ContractReg",
            path: "/contract/reg",
            sub: [],
          },
          {
            id: "4-2-2",
            title: "계약 승인현황",
            name: "ContractApproveStatus",
            path: "/contract/approve/status",
            sub: [
              {
                id: "4-2-2-1",
                title: "계약내역 상세",
                name: "ContractApproveStatusDetail",
                path: "/contract/approve/status/:id",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5",
    title: "공지사항",
    auth: ["COM"],
    sub: [
      {
        id: "5-1",
        title: "공지사항",
        redirect: "/notice/list",
        path: "/notice",
        sub: [
          {
            id: "5-1-1",
            title: "공지사항",
            name: "NoticeList",
            path: "/notice/list",
            sub: [
              {
                id: "5-1-1-1",
                title: "공지사항 상세",
                name: "NoticeDetail",
                path: "/notice/detail/:id",
              },
            ],
          },
        ],
      },
    ],
  },
  // 관리자
  {
    id: "11",
    title: "파트너관리",
    auth: ["ADM"],
    sub: [
      {
        id: "11-1",
        auth: "PAT_A",
        title: "파트너 신청 내역",
        redirect: "/company/status",
        path: "/company/status",
        sub: [
          {
            id: "11-1-1",
            auth: "PAT_A",
            title: "신청현황",
            name: "CompanyStatus",
            path: "/company/status",
            sub: [
              {
                id: "11-1-1-1",
                auth: "PAT_A",
                title: "신청현황 상세",
                name: "CompanyStatusDetail",
                path: "/company/status/:id",
              },
            ],
          },
          {
            id: "11-1-2",
            auth: "PAT_A",
            title: "신청승인 현황",
            name: "CompanyApprove",
            path: "/company/approve",
            sub: [
              {
                id: "11-1-2-1",
                auth: "PAT_A",
                title: "신청승인 현황 상세",
                name: "CompanyApproveDetail",
                path: "/company/approve/:id",
              },
            ],
          },
        ],
      },
      {
        id: "11-2",
        auth: "PAT_R",
        title: "파트너 등록 내역",
        redirect: "/company/reg",
        path: "/company/reg",
        sub: [
          {
            id: "11-2-1",
            auth: "PAT_R",
            title: "파트너정보",
            name: "CompanyRegList",
            path: "/company/reg",
            sub: [
              {
                id: "11-2-1-1",
                auth: "PAT_R",
                title: "파트너정보",
                name: "CompanyPartenerInfo",
                path: "/company/partener/info/:id",
              },
              {
                id: "11-2-1-2",
                auth: "PAT_R",
                title: "프로필",
                name: "CompanyProfile",
                path: "/company/profile/:id",
              },
              {
                id: "11-2-1-3",
                auth: "PAT_R",
                title: "신청 항목",
                name: "CompanyPaymentApprove",
                path: "/company/payment/approve/:id",
              },
              {
                id: "11-2-1-4",
                auth: "PAT_R",
                title: "등록 현황",
                name: "CompanyPaymentStatus",
                path: "/company/payment/status/:id",
              },
              {
                id: "11-2-1-5",
                auth: "PAT_R",
                title: "지난 등록 내역",
                name: "CompanyPrevList",
                path: "/company/payment/prev/:id",
              },
              {
                id: "11-2-1-6",
                auth: "PAT_R",
                title: "지난 등록 내역 상세",
                name: "CompanyPrevDetail",
                path: "/company/payment/prev/:id/:partId",
              },
            ],
          },
        ],
      },
      {
        id: "11-3",
        auth: "PAT_C",
        title: "계약 내역",
        redirect: "/company/contract/approve",
        path: "/company/contract/approve",
        sub: [
          {
            id: "11-3-1",
            auth: "PAT_C",
            title: "계약신청 현황",
            name: "CompanyContractApprove",
            path: "/company/contract/approve",
            sub: [
              {
                id: "11-3-1-1",
                auth: "PAT_C",
                title: "계약신청 현황 상세",
                name: "CompanyContractApproveDetail",
                path: "/company/contract/approve/:id",
              },
            ],
          },
          {
            id: "11-3-2",
            auth: "PAT_C",
            title: "계약 현황",
            name: "CompanyContract",
            path: "/company/contract",
            sub: [
              {
                id: "11-3-2-1",
                auth: "PAT_C",
                title: "계약 현황 상세",
                name: "CompanyContractDetail",
                path: "/company/contract/:id",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "12",
    title: "멤버관리",
    auth: ["ADM"],
    sub: [
      {
        id: "12-1",
        auth: "MEM_A",
        title: "멤버 가입 정보",
        redirect: "/member/reg/info",
        path: "/member/reg/info",
        sub: [
          {
            id: "12-1-1",
            auth: "MEM_A",
            title: "가입중 멤버",
            name: "MemberRegInfo",
            path: "/member/reg/info",
            sub: [],
          },
          {
            id: "12-1-2",
            auth: "MEM_A",
            title: "가입해지 멤버",
            name: "MemberCancelInfo",
            path: "/member/cancel/info",
            sub: [],
          },
        ],
      },
      {
        id: "12-2",
        auth: "MEM_B",
        title: "멤버 결제 내역",
        redirect: "/member/payment/status",
        path: "/member/payment/status",
        sub: [
          {
            id: "12-2-1",
            auth: "MEM_B",
            title: "결제 현황",
            name: "MemberPaymentStatus",
            path: "/member/payment/status",
            sub: [],
          },
          {
            id: "12-2-2",
            auth: "MEM_B",
            title: "취소 현황",
            name: "MemberPaymentCancel",
            path: "/member/payment/cancel",
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: "13",
    title: "정산관리",
    auth: ["ADM"],
    sub: [
      {
        id: "13-1",
        auth: "ALL_P",
        title: "전체 결제 내역",
        redirect: "/all/payment/status",
        path: "/all/payment/status",
        sub: [
          {
            id: "13-1-1",
            auth: "ALL_P",
            title: "결제 현황",
            name: "AllPaymentStatus",
            path: "/all/payment/status",
            sub: [],
          },
          {
            id: "13-1-2",
            auth: "ALL_P",
            title: "매출 현황",
            name: "AllPaymentSale",
            path: "/all/payment/sale",
            sub: [],
          },
          {
            id: "13-1-3",
            auth: "ALL_P",
            title: "결제 오류 현황",
            name: "AllPaymentError",
            path: "/all/payment/error",
            sub: [],
          },
        ],
      },
      {
        id: "13-2",
        auth: "CAL_P",
        title: "파트너 정산 내역",
        redirect: "/all/payment/partner",
        path: "/all/payment/partner",
        sub: [
          {
            id: "13-2-1",
            auth: "CAL_P",
            title: "정산 내역",
            name: "AllPaymentPartner",
            path: "/all/payment/partner",
            sub: [],
          },
          {
            id: "13-2-2",
            auth: "CAL_P",
            title: "정산 요청 내역",
            name: "AllPaymentTax",
            path: "/all/payment/tax",
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: "14",
    title: "알림관리",
    auth: ["ADM"],
    sub: [
      {
        id: "14-1",
        auth: "ALA_P",
        title: "파트너 알림",
        redirect: "/alarm/company/list",
        path: "/alarm/company/list",
        sub: [
          {
            id: "14-1-1",
            auth: "ALA_P",
            title: "자동알림",
            name: "AlarmCompanyList",
            path: "/alarm/company/list",
            sub: [],
          },
        ],
      },
      {
        id: "14-2",
        auth: "ALA_M",
        title: "멤버 알림",
        redirect: "/alarm/member/list",
        path: "/alarm/member/list",
        sub: [
          {
            id: "14-2-1",
            auth: "ALA_M",
            title: "자동알림",
            name: "AlarmMemberList",
            path: "/alarm/member/list",
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: "15",
    title: "공지사항",
    auth: ["ADM"],
    sub: [
      {
        id: "15-1",
        auth: "NOT_P",
        title: "파트너 공지사항",
        redirect: "/notice/company/list",
        path: "/notice/company/list",
        sub: [
          {
            id: "15-1-1",
            auth: "NOT_P",
            title: "공지사항",
            name: "NoticeCompanyList",
            path: "/notice/company/list",
            sub: [
              {
                id: "15-1-1-1",
                auth: "NOT_P",
                title: "공지사항 상세",
                name: "NoticeCompanyDetail",
                path: "/notice/company/detail/:id",
              },
              {
                id: "15-1-1-2",
                auth: "NOT_P",
                title: "공지사항 수정",
                name: "NoticeCompanyAdd",
                path: "/notice/company/add/:id",
              },
            ],
          },
          {
            id: "15-1-2",
            auth: "NOT_P",
            title: "공지사항 추가",
            name: "NoticeCompanyAdd",
            path: "/notice/company/add",
            sub: [],
          },
        ],
      },
      {
        id: "15-2",
        auth: "NOT_M",
        title: "멤버 공지사항",
        redirect: "/notice/member/list",
        path: "/notice/member/list",
        sub: [
          {
            id: "15-2-1",
            auth: "NOT_M",
            title: "공지사항",
            name: "NoticeMemberList",
            path: "/notice/member/list",
            sub: [
              {
                id: "15-2-1-1",
                auth: "NOT_M",
                title: "공지사항 상세",
                name: "NoticeMemberDetail",
                path: "/notice/member/detail/:id",
              },
              {
                id: "15-2-1-2",
                auth: "NOT_M",
                title: "공지사항 수정",
                name: "NoticeMemberAdd",
                path: "/notice/member/add/:id",
              },
            ],
          },
          {
            id: "15-2-2",
            auth: "NOT_M",
            title: "공지사항 추가",
            name: "NoticeMemberAdd",
            path: "/notice/member/add",
            sub: [],
          },
        ],
      },
    ],
  },
  {
    id: "16",
    title: "시스템관리",
    auth: ["ADM"],
    sub: [
      {
        id: "16-1",
        auth: "MAN_A",
        title: "운영 계정 관리",
        redirect: "/system/auth/list",
        path: "/system/auth/list",
        sub: [
          {
            id: "16-1-1",
            auth: "MAN_A",
            title: "계정 현황",
            name: "SystemAuthList",
            path: "/system/auth/list",
            sub: [
              {
                id: "16-1-1-1",
                auth: "MAN_A",
                title: "계정 현황",
                name: "SystemAuthDetail",
                path: "/system/auth/add/:id",
                sub: [],
              },
            ],
          },
          {
            id: "16-1-2",
            auth: "MAN_A",
            title: "계정 추가",
            name: "SystemAuthAdd",
            path: "/system/auth/add",
            sub: [],
          },
        ],
      },
      {
        id: "16-2",
        auth: "CAT_P",
        title: "파트너 카테고리 관리",
        redirect: "/system/category",
        path: "/system/category",
        sub: [
          {
            id: "16-2-1",
            auth: "CAT_P",
            title: "카테고리 설정",
            name: "SystemCategory",
            path: "/system/category",
            sub: [],
          },
        ],
      },
      {
        id: "16-3",
        auth: "APP_S",
        title: "앱 시스템 관리",
        redirect: "/system/app",
        path: "/system/app",
        sub: [
          {
            id: "16-3-1",
            auth: "APP_S",
            title: "안내글 설정",
            name: "SystemAppInfo",
            path: "/system/app",
            sub: [],
          },
        ],
      },
      {
        id: "16-4",
        auth: "CON_C",
        title: "계약내용 설정",
        redirect: "/system/contract/agree",
        path: "/system/contract/agree",
        sub: [
          {
            id: "16-4-1",
            auth: "CON_C",
            title: "결제 동의 설정",
            name: "SystemContractAgree",
            path: "/system/contract/agree",
            sub: [],
          },
          {
            id: "16-4-2",
            auth: "CON_C",
            title: "파트너 계약내용 설정",
            name: "SystemContractPartener",
            path: "/system/contract/partener",
            sub: [],
          },
        ],
      },
      {
        id: "16-5",
        auth: "MEM_T",
        title: "회원가입약관 설정",
        redirect: "/system/sign/agree",
        path: "/system/sign/agree",
        sub: [
          {
            id: "16-5-1",
            auth: "MEM_T",
            title: "소비자 약관 설정",
            name: "SystemSignAgree",
            path: "/system/sign/agree",
            sub: [],
          },
          {
            id: "16-5-2",
            auth: "MEM_T",
            title: "파트너 약관 설정",
            name: "SystemSignPartener",
            path: "/system/sign/partener",
            sub: [],
          },
        ],
      },
    ],
  },
];
