import '@/plugins/global-string'
import Vue from 'vue'
import axios from 'axios'
import { v4 } from 'uuid'
import { nanoid } from 'nanoid'
import qs from 'qs'
import _ from 'lodash'
import { makeLog } from '@/modules/util'

axios.defaults.showLoader = true

Vue.prototype.BASE_URL = 'https://medal.sailors.kr'
Vue.prototype._ = _
Vue.prototype.axios = axios
Vue.prototype.makeLog = makeLog
Vue.prototype.$uuid = v4
Vue.prototype.$nnid = nanoid
Vue.prototype.$axios = async ({ url = '', params = null, type = '', method = 'POST', responseType = '', withCredentials = true }) => {
  try {
    const headers = type === 'MULTI'
      ? { 'Content-Type': 'multipart/form-data' }
      : { 'Content-Type': 'application/x-www-form-urlencoded' }
    const data = type === 'MULTI'
      ? params
      : qs.stringify(params)
    const response = await axios({
      method: method,
      url,
      data,
      baseURL: 'https://medal.sailors.kr',
      responseType: responseType,
      proxy: true,
      withCredentials: withCredentials,
      headers
    })
    return response.data
  }
  catch (err) {
    throw new Error(err)
  }
}


/**@ 모달 만들기 공통 모듈 ***/
Vue.prototype.$openAlert = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_ALERT,
    size: _obj.SIZE_T,
    btnScndClr: _obj.CLR_S,
    btnSize: _obj.SIZE_T,
    btnScndNm: '확인',
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openConfirm = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_CONFIRM,
    size: _obj.SIZE_T,
    btnPrmyNm: '예',
    btnScndNm: '아니요',
    btnPrmyClr: _obj.CLR_M,
    btnScndClr: _obj.CLR_W,
    btnSize: _obj.SIZE_T,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openInfo = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_INFO,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openPrompt = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_PROMPT,
    size: _obj.SIZE_L,
    btnPrmyNm: '확인',
    btnScndNm: '취소',
    btnPrmyClr: _obj.CLR_M,
    btnScndClr: _obj.CLR_W,
    btnSize: _obj.SIZE_S,
    defTx: '',
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openAddress = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_ADDRESS,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openBasicInfo = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_BASIC_INFO,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openBusinessInfo = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_BUSINESS_INFO,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openBankInfo = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_BANK_INFO,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openRejectReason = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_REJECT_REASON,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openCommission = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_COMMISSION,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openMemberCode = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_MEMBER_CODE,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openPartenerCode = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_PARTENER_CODE,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openPaymentType = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_PAYMENT_TYPE,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openPaymentGroup = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_PAYMENT_GROUP,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openPaymentNumber = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_PAYMENT_NUMBER,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openPaymentCancel = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_PAYMENT_CANCEL,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openPaymentCalculate = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_PAYMENT_CALCULATE,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openDuplicate = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_DUPLICATE,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openEmp = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_EMP,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openError = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_ERROR,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openAppInfo = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_APPINFO,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openReceipt = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_RECEIPT,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openAlarm = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_ALARM,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}

Vue.prototype.$openAgree = async (options, _obj) => {
  await _obj.$store.dispatch('A_MODAL_INFO', {
    ..._obj.$store.getters.G_MODAL_RESET,
    uiType: _obj.TYPE_AGREE,
    ...options,
  })
  await _obj.$store.dispatch('A_MODAL_TOGGLE', true)
}