<template>
  <div class="bell-alert pb-16 under-finish">
    <div
      class="d-flex space-between bell-alert-header p-16 border-bottom align-items-center"
    >
      <span class="fm-14 f-g70">전체알람</span>
      <div>
        <span
          class="fm-14 f-g40"
          style="cursor: pointer"
          @click="deleteBellData"
          >읽은 알림 모두삭제</span
        >
      </div>
    </div>
    <div
      class="bell-alert-body d-flex space-between align-items-center"
      v-for="data in bellList"
      :key="data.esntlId"
    >
      <div
        class="pt-16"
        :class="`bell-alert-title${data.Aread ? '-read' : ''}`"
      >
        <div class="w-100 d-flex space-between align-items-center">
          <span class="fr-12 f-g60 p-8">{{ data.rDate }}</span>
        </div>

        <div
          class="fb-14 p-8 f-g70"
          :class="{ 'bell-read': data.Aread }"
          style="cursor: pointer"
          @click="goPage(data.url)"
        >
          {{ data.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "@/assets/json/nav.js";

export default {
  name: "BellWrap",
  components: {},
  data() {
    return {
      bellList: [],
    };
  },
  /*** computed ***/
  computed: {},
  /*** watch ***/
  watch: {},
  async mounted() {
    await this.getBellData();
  },
  methods: {
    async getBellData() {
      const rs = await this.$axios({
        url: "/partner/alarm",
        params: {},
      });
      if (rs.result === "SUC") {
        this.bellList = rs.resultList;
        this.$store.dispatch("A_BELL_COUNT", {});
      }
    },
    async deleteBellData() {
      const rs = await this.$axios({
        url: "/partner/alarm/read/del",
        params: {},
      });
      if (rs.result === "SUC") {
        await this.getBellData();
      }
    },
    goPage(url) {
      const link = url;
      this.$router.push({
        path: link,
      });
    },
  },
};
</script>

<style scoped>
.bell-read {
  color: #babdcc !important;
}
.bell-read-circle {
  background-color: #babdcc !important;
}
</style>
