const A_REJECT_REASON_DISABLED = ({ commit }, payload) => {
  commit("M_REJECT_REASON_DISABLED", payload);
};
const A_REJECT_REASON = ({ commit }, payload) => {
  commit("M_REJECT_REASON", payload);
};

export default {
  A_REJECT_REASON_DISABLED,
  A_REJECT_REASON,
};
