import Vue from "vue";

/*** Pager ***/
Vue.prototype.PAGER_CNT = 10;
Vue.prototype.PAGER_NUM_OF_DATA = 10;

/*** Modal Type ***/
Vue.prototype.TYPE_ALERT = "ALERT";
Vue.prototype.TYPE_CONFIRM = "CONFIRM";
Vue.prototype.TYPE_INFO = "INFO";
Vue.prototype.TYPE_PROMPT = "PROMPT";
Vue.prototype.TYPE_ADDRESS = "ADDRESS";
Vue.prototype.TYPE_BASIC_INFO = "BASIC_INFO";
Vue.prototype.TYPE_BUSINESS_INFO = "BUSINESS_INFO";
Vue.prototype.TYPE_BANK_INFO = "BANK_INFO";
Vue.prototype.TYPE_REJECT_REASON = "REJECT_REASON";
Vue.prototype.TYPE_COMMISSION = "COMMISSION";
Vue.prototype.TYPE_MEMBER_CODE = "MEMBER_CODE";
Vue.prototype.TYPE_PARTENER_CODE = "PARTENER_CODE";
Vue.prototype.TYPE_PAYMENT_TYPE = "TYPE_PAYMENT_TYPE";
Vue.prototype.TYPE_PAYMENT_GROUP = "TYPE_PAYMENT_GROUP";
Vue.prototype.TYPE_PAYMENT_NUMBER = "TYPE_PAYMENT_NUMBER";
Vue.prototype.TYPE_PAYMENT_CANCEL = "TYPE_PAYMENT_CANCEL";
Vue.prototype.TYPE_PAYMENT_CALCULATE = "TYPE_PAYMENT_CALCULATE";
Vue.prototype.TYPE_DUPLICATE = "DUPLICATE";
Vue.prototype.TYPE_EMP = "EMP";
Vue.prototype.TYPE_ERROR = "ERROR";
Vue.prototype.TYPE_APPINFO = "APPINFO";
Vue.prototype.TYPE_RECEIPT = "RECEIPT";
Vue.prototype.TYPE_ALARM = "ALARM";
Vue.prototype.TYPE_AGREE = "AGREE";

/*** Modal Text ***/
Vue.prototype.ERR_DELETE = "삭제 오류가 발생하였습니다.";
Vue.prototype.ERR_CREATE = "저장 오류가 발생하였습니다.";
Vue.prototype.ERR_UPDATE = "수정 오류가 발생하였습니다.";
Vue.prototype.ERR_READ = "데이터 읽기 오류가 발생하였습니다.";
Vue.prototype.ERR_XHR = "서버와 통신 오류가 발생하였습니다.";

/*** Alert Text ***/
Vue.prototype.MSG_FROM_EMPTY =
  "• 입력해야할 항목이 비어있을 경우 이곳에 에러메시지가 출력됩니다.";
Vue.prototype.MSG_SAVE = "데이터가 저장되었습니다";

/*** Form Color & Size ***/
Vue.prototype.CLR_W = "w";
Vue.prototype.CLR_M = "m";
Vue.prototype.CLR_S = "s";
Vue.prototype.CLR_B = "b";
Vue.prototype.CLR_G = "g";
Vue.prototype.CLR_WG = "wg";

Vue.prototype.SIZE_F = "full";
Vue.prototype.SIZE_L = "large";
Vue.prototype.SIZE_M = "medium";
Vue.prototype.SIZE_S = "small";
Vue.prototype.SIZE_T = "tiny";
Vue.prototype.SIZE_A = "auto";

/*** Select Options ***/
Vue.prototype.SELT_ORD = [
  { title: "최근순", value: "DESC" },
  { title: "오래된순", value: "ASC" },
];

Vue.prototype.SELT_CNT = [
  { title: "10", value: 10 },
  { title: "15", value: 15 },
  { title: "20", value: 20 },
  { title: "30", value: 30 },
  { title: "50", value: 50 },
];

Vue.prototype.SELT_CAREER = [
  { title: "활동경력", value: "" },
  { title: "1년미만 ~ 1년", value: "1년미만 ~ 1년" },
  { title: "2년", value: "2년" },
  { title: "3년", value: "3년" },
  { title: "4년", value: "4년" },
  { title: "5년", value: "5년" },
  { title: "6년", value: "6년" },
  { title: "7년", value: "7년" },
  { title: "8년", value: "8년" },
  { title: "9년", value: "9년" },
  { title: "10년", value: "10년" },
  { title: "10년 이상", value: "10년 이상" },
];

Vue.prototype.SELT_BANK = [
  { title: "", value: "" },
  { title: "카카오뱅크", value: "카카오뱅크" },
  { title: "KB국민", value: "KB국민" },
  { title: "한국씨티", value: "한국씨티" },
  { title: "부산", value: "부산" },
  { title: "신한", value: "신한" },
  { title: "NH농협", value: "NH농협" },
  { title: "BNK경남", value: "BNK경남" },
  { title: "케이뱅크", value: "케이뱅크" },
  { title: "DGB대구", value: "DGB대구" },
  { title: "광주", value: "광주" },
  { title: "우리", value: "우리" },
  { title: "KDB산업", value: "KDB산업" },
  { title: "제일", value: "제일" },
  { title: "IBK기업", value: "IBK기업" },
  { title: "하나", value: "하나" },
  { title: "전북", value: "전북" },
  { title: "SH수협", value: "SH수협" },
  { title: "제주", value: "제주" },
];

Vue.prototype.SELT_NICK = [
  { title: "선택안함", value: "" },
  { title: "코치님", value: "코치님" },
  { title: "선생님", value: "선생님" },
  { title: "강사님", value: "강사님" },
  { title: "프로님", value: "프로님" },
  { title: "교수님", value: "교수님" },
];

Vue.prototype.SELT_MARKETING = [
  { title: "선택안함", value: "" },
  { title: "인스타그램", value: "IS" },
  { title: "페이스북", value: "FB" },
  { title: "유튜브", value: "YT" },
];

Vue.prototype.SELT_COMPANY_STATUS = [
  { title: "선택안함", value: "" },
  { title: "유형", value: "TYPE" },
  { title: "분야", value: "SPEC" },
  { title: "파트너", value: "COMP" },
  { title: "아이디", value: "EMAIL" },
];

Vue.prototype.SELT_COMPANY_APPROVE = [
  { title: "선택안함", value: "" },
  { title: "유형", value: "TYPE" },
  { title: "분야", value: "SPEC" },
  { title: "승인현황", value: "STATUS" },
  { title: "파트너", value: "COMP" },
  { title: "아이디", value: "EMAIL" },
];

Vue.prototype.SELT_COMPANY_APPROVE_STATUS = [
  { title: "선택안함", value: "" },
  { title: "승인거절", value: "REJ" },
  { title: "승인완료", value: "APP" },
];

Vue.prototype.SELT_COMPANY_STATUS_TYPE = [
  { title: "선택안함", value: "" },
  { title: "신규", value: "NEW" },
  { title: "변경", value: "EDIT" },
];

Vue.prototype.YEAR_LIST = [
  { title: "2020년", value: "2020" },
  { title: "2021년", value: "2021" },
  { title: "2022년", value: "2022" },
];
Vue.prototype.MONTH_LIST = [
  { title: "1월", value: "01" },
  { title: "2월", value: "02" },
  { title: "3월", value: "03" },
  { title: "4월", value: "04" },
  { title: "5월", value: "05" },
  { title: "6월", value: "06" },
  { title: "7월", value: "07" },
  { title: "8월", value: "08" },
  { title: "9월", value: "09" },
  { title: "10월", value: "10" },
  { title: "11월", value: "11" },
  { title: "12월", value: "12" },
];

Vue.prototype.SELT_COMPANY_REG = [
  { title: "선택안함", value: "" },
  { title: "계약유형", value: "TYPE" },
  { title: "파트너명", value: "COMP" },
  { title: "아이디", value: "EMAIL" },
  { title: "파트너코드", value: "CODE" },
  { title: "분야", value: "CATE" },
  { title: "수수료", value: "COMM" },
  { title: "결제항목", value: "PAY" },
];

Vue.prototype.SELT_COMPANY_REG_CONTRACT = [
  { title: "선택안함", value: "" },
  { title: "계약필요", value: "NOT" },
  { title: "사업자", value: "COMP" },
  { title: "개인", value: "PERSON" },
];

Vue.prototype.SELT_COMPANY_REG_COMM = [
  { title: "선택안함", value: "" },
  { title: "설정필요", value: "NEED" },
  { title: "설정완료", value: "FIN" },
];

Vue.prototype.SELT_COMPANY_REG_PAY = [
  { title: "선택안함", value: "" },
  { title: "승인요청", value: "REQ" },
  { title: "미요청", value: "NOT" },
];

Vue.prototype.SELT_CONTRACT_APPROVE = [
  { title: "선택안함", value: "" },
  { title: "승인처리", value: "ACCEPT" },
  { title: "신청유형", value: "APP" },
  { title: "파트너코드", value: "CODE" },
  { title: "이름", value: "NAME" },
  { title: "유형", value: "TYPE" },
  { title: "기간", value: "PERIOD" },
];

Vue.prototype.SELT_CONTRACT_ACCEPT = [
  { title: "선택안함", value: "" },
  { title: "승인요청", value: "REQ" },
  { title: "승인", value: "APP" },
  { title: "미승인", value: "REJ" },
];

Vue.prototype.SELT_CONTRACT_APP = [
  { title: "선택안함", value: "" },
  { title: "신규", value: "APP" },
  { title: "해지", value: "CAN" },
];

Vue.prototype.SELT_CONTRACT_TYPE = [
  { title: "선택안함", value: "" },
  { title: "사업자", value: "COMP" },
  { title: "개인", value: "PERSON" },
];

Vue.prototype.SELT_CONTRACT = [
  { title: "선택안함", value: "" },
  { title: "계약현황", value: "STATUS" },
  { title: "계약번호", value: "CONT" },
  { title: "파트너코드", value: "CODE" },
  { title: "유형", value: "TYPE" },
  { title: "이름", value: "NAME" },
];

Vue.prototype.SELT_CONTRACT_STATUS = [
  { title: "선택안함", value: "" },
  { title: "계약중", value: "USED" },
  { title: "계약만료", value: "END" },
  { title: "중도해지", value: "TERM" },
];

Vue.prototype.SELT_COMMISSION_MONTH = [
  { title: "선택", value: "" },
  { title: "3개월", value: "3" },
  { title: "6개월", value: "6" },
  { title: "9개월", value: "9" },
  { title: "12개월", value: "12" },
  { title: "24개월", value: "24" },
];

Vue.prototype.SELT_PAYMENT_MONTH = [
  { title: "선택안함", value: "" },
  { title: "1", value: "1" },
  { title: "2", value: "2" },
  { title: "3", value: "3" },
  { title: "4", value: "4" },
  { title: "5", value: "5" },
  { title: "6", value: "6" },
  { title: "7", value: "7" },
  { title: "8", value: "8" },
  { title: "9", value: "9" },
  { title: "10", value: "10" },
  { title: "11", value: "11" },
  { title: "12", value: "12" },
  { title: "13", value: "13" },
  { title: "14", value: "14" },
  { title: "15", value: "15" },
  { title: "16", value: "16" },
  { title: "17", value: "17" },
  { title: "18", value: "18" },
  { title: "19", value: "19" },
  { title: "20", value: "20" },
  { title: "21", value: "21" },
  { title: "22", value: "22" },
  { title: "23", value: "23" },
  { title: "24", value: "24" },
];

Vue.prototype.SELT_PAYMENT_MINMAX = [
  { title: "선택안함", value: "" },
  { title: "1", value: "1" },
  { title: "2", value: "2" },
  { title: "3", value: "3" },
  { title: "4", value: "4" },
  { title: "5", value: "5" },
  { title: "6", value: "6" },
  { title: "7", value: "7" },
  { title: "8", value: "8" },
  { title: "9", value: "9" },
  { title: "10", value: "10" },
  { title: "11", value: "11" },
  { title: "12", value: "12" },
  { title: "13", value: "13" },
  { title: "14", value: "14" },
  { title: "15", value: "15" },
  { title: "16", value: "16" },
  { title: "17", value: "17" },
  { title: "18", value: "18" },
  { title: "19", value: "19" },
  { title: "20", value: "20" },
  { title: "21", value: "21" },
  { title: "22", value: "22" },
  { title: "23", value: "23" },
  { title: "24", value: "24" },
  { title: "25", value: "25" },
  { title: "26", value: "26" },
  { title: "27", value: "27" },
  { title: "28", value: "28" },
  { title: "29", value: "29" },
  { title: "30", value: "30" },
  { title: "31", value: "31" },
];

Vue.prototype.SELT_PAYMENT_PREV = [
  { title: "선택안함", value: "" },
  { title: "결제항목 코드", value: "CODE" },
  { title: "분야", value: "CATE" },
];

Vue.prototype.SELT_CONTRACT_LIST = [
  { title: "선택안함", value: "" },
  { title: "계약현황", value: "STATUS" },
  { title: "계약번호", value: "NUMBER" },
  { title: "계약명", value: "CONT" },
  { title: "계약기간", value: "PERIOD" },
];

Vue.prototype.SELT_CONTRACT_MONTH = [
  { title: "선택안함", value: "" },
  { title: "3개월", value: "3" },
  { title: "6개월", value: "6" },
  { title: "9개월", value: "9" },
  { title: "12개월", value: "12" },
  { title: "24개월", value: "24" },
];

Vue.prototype.SELT_CONTRACT_APPROVE_STAUTS_LIST = [
  { title: "선택안함", value: "" },
  { title: "승인현황", value: "STATUS" },
  { title: "신청유형", value: "TYPE" },
  { title: "계약명", value: "CONT" },
];

Vue.prototype.SELT_CONTRACT_APPROVE_STATUS = [
  { title: "선택안함", value: "" },
  { title: "처리중", value: "NOT" },
  { title: "승인거절", value: "REJ" },
  { title: "승인완료", value: "APP" },
];

Vue.prototype.SELT_CONTRACT_REG_STATUS = [
  { title: "선택안함", value: "" },
  { title: "중도해지", value: "REJ" },
  { title: "계약신청", value: "APPLY" },
];

Vue.prototype.SELT_MEMBER_REG_INFO = [
  { title: "선택안함", value: "" },
  { title: "이름", value: "NAME" },
  { title: "성별", value: "GENDER" },
  { title: "멤버코드", value: "CODE" },
  { title: "연락처", value: "PHONE" },
  { title: "아이디", value: "EMAIL" },
  { title: "로그인 계정", value: "LOGIN" },
  { title: "웹 접속", value: "WEB" },
  { title: "앱 접속", value: "MOB" },
];

Vue.prototype.SELT_MEMBER_CANCEL_INFO = [
  { title: "선택안함", value: "" },
  { title: "이름", value: "NAME" },
  { title: "성별", value: "GENDER" },
  { title: "멤버코드", value: "CODE" },
  { title: "연락처", value: "PHONE" },
  { title: "웹 접속", value: "WEB" },
  { title: "앱 접속", value: "MOB" },
];

Vue.prototype.SELT_MEMBER_GENDER = [
  { title: "선택안함", value: "" },
  { title: "남", value: "M" },
  { title: "여", value: "F" },
];

Vue.prototype.SELT_MEMBER_LOGIN = [
  { title: "선택안함", value: "" },
  { title: "카카오", value: "KAO" },
  { title: "애플", value: "APE" },
  { title: "네이버", value: "NVR" },
  { title: "구글", value: "GLE" },
  { title: "이메일", value: "MAL" },
];

Vue.prototype.SELT_MEMBER_WEB = [
  { title: "선택안함", value: "" },
  { title: "0", value: "A" },
  { title: "1~10", value: "B" },
  { title: "11~50", value: "C" },
  { title: "51~100", value: "D" },
  { title: "101~500", value: "E" },
  { title: "501 이상", value: "F" },
];

Vue.prototype.SELT_MEMBER_APP = [
  { title: "선택안함", value: "" },
  { title: "0", value: "A" },
  { title: "1~10", value: "B" },
  { title: "11~50", value: "C" },
  { title: "51~100", value: "D" },
  { title: "101~500", value: "E" },
  { title: "501 이상", value: "F" },
];

Vue.prototype.SELT_PAYMENT_STATUS_LIST = [
  { title: "선택안함", value: "" },
  { title: "결제유형", value: "TYPE" },
  { title: "결제항목", value: "SERVICE" },
  { title: "이름", value: "NAME" },
  { title: "성별", value: "GENDER" },
  { title: "멤버코드", value: "CODE" },
  { title: "아이디", value: "EMAIL" },
  { title: "결제번호", value: "NUMBER" },
];

Vue.prototype.SELT_PAYMENT_TYPE = [
  { title: "선택안함", value: "" },
  { title: "자동결제", value: "N" },
  { title: "위약금", value: "P" },
];

Vue.prototype.SELT_COMPANY_PAYMENT_PREV = [
  { title: "선택안함", value: "" },
  { title: "파트너명", value: "NAME" },
  { title: "결제항목 코드", value: "PAY_CODE" },
  { title: "아이디", value: "ID" },
  { title: "파트너코드", value: "PART_CODE" },
  { title: "계약유형", value: "TYPE" },
  { title: "분야", value: "SPEC" },
];

Vue.prototype.SELT_MEMBER_PAYMENT_STATUS = [
  { title: "선택안함", value: "" },
  { title: "결제유형", value: "TYPE" },
  { title: "결제항목", value: "SERVICE" },
  { title: "이름", value: "NAME" },
  { title: "성별", value: "GENDER" },
  { title: "멤버코드", value: "CODE" },
  { title: "아이디", value: "EMAIL" },
  { title: "결제번호", value: "NUMBER" },
];

Vue.prototype.SELT_PAYMENT_STATUS = [
  { title: "선택안함", value: "" },
  { title: "유형", value: "TYPE" },
  { title: "결제자", value: "NAME" },
  { title: "결제금액", value: "PRICE" },
  { title: "결제번호", value: "PAYMENT" },
  { title: "결제항목", value: "SERVICE" },
];

Vue.prototype.SELT_PAYMENT_STATUS_TYPE = [
  { title: "선택안함", value: "" },
  { title: "자동결제", value: "N" },
  { title: "결제취소 신청", value: "NCR" },
  { title: "결제 취소", value: "NC" },
  { title: "위약금", value: "P" },
  { title: "해지철회 신청", value: "PCR" },
  { title: "위약금 취소", value: "PC" },
];

Vue.prototype.SELT_PAYMENT_ERROR = [
  { title: "선택안함", value: "" },
  { title: "결제그룹번호", value: "GROUP" },
  { title: "유형", value: "TYPE" },
  { title: "결제자", value: "MEMBER" },
  { title: "항목번호", value: "SERVICE" },
  { title: "결제번호", value: "PAYMENT" },
];

Vue.prototype.SELT_PAYMENT_CALCULATE = [
  { title: "선택안함", value: "" },
  { title: "정산완료", value: "CAL" },
];

Vue.prototype.SELT_PAYMENT_CALCULATE_TYPE = [
  { title: "선택안함", value: "" },
  { title: "미완료", value: "NOT" },
  { title: "완료", value: "END" },
];

Vue.prototype.SELT_POPBILL_BANK = [
  { title: "은행선택", value: "" },
  { title: "카카오뱅크", value: "0090" },
  { title: "KB국민", value: "0004" },
  { title: "한국씨티", value: "0027" },
  { title: "부산", value: "0032" },
  { title: "신한", value: "0088" },
  { title: "NH농협", value: "0011" },
  { title: "BNK경남", value: "0039" },
  { title: "케이뱅크", value: "0089" },
  { title: "DGB대구", value: "0031" },
  { title: "광주", value: "0034" },
  { title: "우리", value: "0020" },
  { title: "KDB산업", value: "0002" },
  { title: "제일", value: "0023" },
  { title: "IBK기업", value: "0003" },
  { title: "하나", value: "0081" },
  { title: "전북", value: "0037" },
  { title: "SH수협", value: "0007" },
  { title: "제주", value: "0035" },
];

Vue.prototype.SELT_MYINFO_ACCOUNT = [
  { title: "개인명의 계좌", value: "P" },
  { title: "법인명의 계좌", value: "B" },
];

Vue.prototype.SELT_APPINFO_EXPIREDATE = [
  { title: "항시", value: "NULL" },
  { title: "1일", value: "1" },
  { title: "2일", value: "2" },
  { title: "3일", value: "3" },
  { title: "4일", value: "4" },
  { title: "5일", value: "5" },
  { title: "6일", value: "6" },
  { title: "7일", value: "7" },
  { title: "8일", value: "8" },
  { title: "9일", value: "9" },
  { title: "10일", value: "10" },
  { title: "11일", value: "11" },
  { title: "12일", value: "12" },
  { title: "13일", value: "13" },
  { title: "14일", value: "14" },
  { title: "15일", value: "15" },
  { title: "16일", value: "16" },
  { title: "17일", value: "17" },
  { title: "18일", value: "18" },
  { title: "19일", value: "19" },
  { title: "20일", value: "20" },
  { title: "21일", value: "21" },
  { title: "22일", value: "22" },
  { title: "23일", value: "23" },
  { title: "24일", value: "24" },
  { title: "25일", value: "25" },
  { title: "26일", value: "26" },
  { title: "27일", value: "27" },
  { title: "28일", value: "28" },
  { title: "29일", value: "29" },
  { title: "30일", value: "30" },
];

Vue.prototype.SELT_ALL_PAYMENT_STATUS = [
  { title: "선택안함", value: "" },
  { title: "결제자", value: "NAME" },
  { title: "유형", value: "TYPE" },
  { title: "결제번호", value: "PAYMENT" },
  { title: "파트너코드", value: "COMP" },
  { title: "항목번호", value: "SERVICE" },
];

Vue.prototype.SELT_ALL_PAYMENT_TYPE = [
  { title: "선택안함", value: "" },
  { title: "일반", value: "N" },
  { title: "결제취소신청", value: "NCR" },
  { title: "결제취소", value: "NC" },
  { title: "위약금", value: "P" },
  { title: "해지철회신청", value: "PCR" },
  { title: "위약금취소", value: "PC" },
];

Vue.prototype.SELT_ALL_PAYMENT_SALE = [
  { title: "선택안함", value: "" },
  { title: "유형", value: "TYPE" },
  { title: "파트너코드", value: "COMP" },
  { title: "항목번호", value: "SERVICE" },
  { title: "결제번호", value: "PAYMENT" },
];

Vue.prototype.SELT_ALL_PAYMENT_SALE_TYPE = [
  { title: "선택안함", value: "" },
  { title: "자동결제", value: "N" },
  { title: "위약금", value: "P" },
];

Vue.prototype.SELT_ALL_PAYMENT_ERROR = [
  { title: "선택안함", value: "" },
  { title: "결제그룹번호", value: "GROUP" },
  { title: "유형", value: "TYPE" },
  { title: "멤버코드", value: "MEMBER" },
  { title: "파트너코드", value: "PARTNER" },
  { title: "항목번호", value: "SERVICE" },
  { title: "결제번호", value: "PAYMENT" },
];

Vue.prototype.SELT_ALL_PAYMENT_ERROR_TYPE = [
  { title: "선택안함", value: "" },
  { title: "결제오류", value: "E" },
  { title: "이용정지", value: "S" },
  { title: "한도초과", value: "M" },
];

Vue.prototype.SELT_ALL_PAYMENT_PARTENER = [
  { title: "선택안함", value: "" },
  { title: "정산현황", value: "CAL" },
  { title: "정산유형", value: "TYPE" },
  { title: "파트너코드", value: "CODE" },
  { title: "정산계좌", value: "ACCOUNT" },
];

Vue.prototype.SELT_ALL_PAYMENT_PARTENER_CAL = [
  { title: "선택안함", value: "" },
  { title: "확인대기", value: "NOT" },
  { title: "정산완료", value: "END" },
];

Vue.prototype.SELT_ALL_PAYMENT_PARTENER_TYPE = [
  { title: "선택안함", value: "" },
  { title: "개인", value: "P" },
  { title: "사업자", value: "C" },
];

Vue.prototype.SELT_ALL_PAYMENT_TAX = [
  { title: "선택안함", value: "" },
  { title: "발행상태", value: "PUB" },
  { title: "정산유형", value: "TYPE" },
  { title: "파트너코드", value: "CODE" },
];

Vue.prototype.SELT_ALL_PAYMENT_TAX_PUB = [
  { title: "선택안함", value: "" },
  { title: "확인요청", value: "NOT" },
  { title: "확인완료", value: "END" },
];
