<template>
  <div class="my d-flex justify-content-center align-items-center">
    <span
      class="fb-14"
      style="color: #babdcc; margin-right: 16px"
      v-if="profile"
      >{{ profile.email }}</span
    >
    <span
      class="my_info fr-14"
      :class="{ 'margin-right-20': !isCom }"
      style="color: #babdcc; cursor: pointer"
      @click="goInfoPage"
      >내 정보</span
    >
    <!-- 벨 -->
    <div class="bell" @click="clickBell" v-if="isCom">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7212 13.2163C16.405 12.2562 16.2434 11.252 16.2425 10.2413V7.74375C16.2655 6.16123 15.7008 4.62642 14.6575 3.43625C13.6178 2.2442 12.1748 1.47774 10.605 1.28375C9.7375 1.19375 8.855 1.28375 8.0175 1.5675C7.18 1.83625 6.4175 2.285 5.775 2.88375C5.1293 3.46706 4.61464 4.18068 4.265 4.9775C3.91362 5.77402 3.73022 6.63443 3.72625 7.505V10.255C3.72442 11.2616 3.56282 12.2616 3.2475 13.2175L2.5 15.4288L3.09875 16.2513H7.48C7.48 16.9088 7.74875 17.5513 8.2125 18.0163C8.675 18.4788 9.31875 18.7488 9.9775 18.7488C10.635 18.7488 11.2775 18.48 11.7412 18.0163C12.205 17.5525 12.475 16.9088 12.475 16.2513H16.8562L17.4538 15.4288L16.7212 13.2163ZM10.8588 17.15C10.7437 17.2673 10.6067 17.3607 10.4554 17.4249C10.3042 17.489 10.1418 17.5226 9.9775 17.5238C9.81293 17.5226 9.65021 17.4889 9.49874 17.4245C9.34727 17.3602 9.21005 17.2664 9.095 17.1488C8.97747 17.0338 8.88383 16.8968 8.81947 16.7456C8.75511 16.5943 8.7213 16.4319 8.72 16.2675H11.2188C11.224 16.4315 11.1947 16.5948 11.1329 16.7468C11.0711 16.8988 10.9782 17.0362 10.86 17.15H10.8588ZM3.965 15.0125L4.42875 13.62C4.79039 12.5352 4.97731 11.3998 4.9825 10.2563V7.505C4.9825 6.80125 5.1325 6.11375 5.41625 5.485C5.7 4.8425 6.10375 4.27375 6.6275 3.81C7.15125 3.3325 7.765 2.9725 8.4225 2.76375C9.095 2.53875 9.7975 2.46375 10.485 2.53875C11.745 2.70443 12.9003 3.32721 13.7312 4.28875C14.5625 5.25149 15.0093 6.48703 14.9862 7.75875V10.2713C14.9862 11.4075 15.1662 12.5438 15.54 13.635L16.0038 15.0263H3.96625V15.0113L3.965 15.0125Z"
          fill="var(--gray30)"
        />
        <circle
          cx="15"
          cy="3"
          r="3"
          fill="var(--main60)"
          v-if="bellCount !== 0"
        />
      </svg>
    </div>
    <!-- 로그아웃 -->
    <span
      class="logout fr-14"
      style="color: #babdcc; cursor: pointer"
      @click="doLogout"
      >로그아웃</span
    >
  </div>
</template>

<script>
import Bell from "@/components/common/header-wrapper/Bell";
import { mapGetters } from "vuex";

export default {
  name: "UserWrap",
  components: { Bell },
  data() {
    return {
      isCom: false,
      profile: null,
      showBell: false,
      bellCount: 0,
    };
  },
  /*** computed ***/
  computed: {
    ...mapGetters(["G_SHOW_BELL", "G_BELL_COUNT"]),
  },
  /*** watch ***/
  watch: {
    G_SHOW_BELL() {
      this.showBell = this["G_SHOW_BELL"];
    },
    G_BELL_COUNT() {
      this.bellCount = this["G_BELL_COUNT"];
    },
  },
  mounted() {
    this.isCom = localStorage.getItem("auth") === "COM";
    if (localStorage.getItem("auth") === "COM") {
      this.getProfileData();
    }
  },
  methods: {
    goInfoPage() {
      this.$router.push({
        path: `/myInfo/company`,
        meta: { id: "1-1-1" },
      });
    },
    async getProfileData() {
      const rs = await this.$axios({
        url: "/partner/myInfo",
        params: {},
      });
      if (rs.result === "SUC") {
        this.profile = rs.resultData.length > 0 ? rs.resultData[0] : null;
        console.log("프로필 조회 성공");
      } else {
        console.log("프로필 조회 실패");
      }
    },
    doLogout() {
      const auth = localStorage.getItem("auth");
      localStorage.setItem("auth", "");
      if (auth === "COM") {
        this.$router.push({
          path: `/login`,
          meta: { id: "0-1-1" },
        });
      } else {
        this.$router.push({
          path: `/operator`,
          meta: { id: "0-1-9" },
        });
      }
    },
    clickBell() {
      this.$store.dispatch("A_SHOW_BELL", !this.showBell);
    },
  },
};
</script>

<style scoped>
.margin-right-20 {
  margin-right: 20px;
}
.bell {
  margin-right: 12px;
  cursor: pointer;
}
</style>
