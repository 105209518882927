<template>
  <div class="logo d-flex align-items-center">
    <a href="#" class="square fm-14">MEDAL</a>
    <!-- <a href="#" class="master_coach_admin_page fm-16">마스터코치 관리자 페이지</a> -->
  </div>
</template>

<script>
export default {
  name: 'LogoWrap'
}
</script>

<style scoped lang="scss">

</style>
