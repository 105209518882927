import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import NAV from "./modules/nav";
import MODAL from "./modules/modal";
import LOADING from "./modules/loading";
import PAGER from "./modules/pager";
import PAGER2 from "./modules/pager2";
import FOLDER from "./modules/folder";
import FOLDER2 from "./modules/folder2";
import PREVIEW from "./modules/preview";
import REJECT from "./modules/reject";
import COMMISSION from "./modules/commission";
import CODE from "./modules/code";
import APPINFO from "./modules/appinfo";
import ALARM from "./modules/alarm";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    NAV,
    MODAL,
    LOADING,
    PAGER,
    PAGER2,
    FOLDER,
    FOLDER2,
    PREVIEW,
    REJECT,
    COMMISSION,
    CODE,
    APPINFO,
    ALARM,
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [createPersistedState({ NAV, PAGER, FOLDER, PREVIEW })],
});

export default store;
