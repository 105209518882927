import Vue from 'vue'

/*** UI ***/
import ButtonCommon from '@/components/ui/button/ButtonCommon.vue'
import EditorCommon from '@/components/ui/editor/EditorCommon'
import InputCheck from '@/components/ui/input/InputCheck'
import InputRadio from '@/components/ui/input/InputRadio'
import InputText from '@/components/ui/input/InputText'
import InputTextLbl from '@/components/ui/input/InputTextLbl'
import LabelBox from '@/components/ui/content/LabelBox'
import LabelBoxMission from '@/components/ui/content/LabelBoxMission'
import Pagination from '@/components/ui/pager/Pagination'
import PathContent from '@/components/common/content-wrapper/PathContent'
import PathLocation from '@/components/ui/content/PathLocation'
import SelectBox from '@/components/ui/select/SelectBox'
import IconMedia from '@/components/ui/icon/IconMedia'
import IconFolder from '@/components/ui/icon/IconFolder'
import Tbl from '@/components/ui/table/Tbl'
import TextBox from '@/components/ui/input/TextBox'
import TextBox2 from '@/components/ui/input/TextBox2'
import TextUpload from '@/components/ui/input/TextUpload'
import TimePickerBox from '@/components/ui/date/TimePickerBox'
import UploaderBox from '@/components/ui/uploader/UploaderBox'
import UploaderBoxImg from '@/components/ui/uploader/UploaderBoxImg'
import UploaderBoxTx from '@/components/ui/uploader/UploaderBoxTx'

/*** Aside ***/
import BottomInfo from '@/components/ui/aside/BottomInfo'
import ModalCommon from '@/components/ui/modal/ModalCommon'


Vue.component('button-common', ButtonCommon)
Vue.component('editor-common', EditorCommon)
Vue.component('input-check', InputCheck)
Vue.component('input-radio', InputRadio)
Vue.component('input-text', InputText)
Vue.component('input-text-lbl', InputTextLbl)
Vue.component('label-box', LabelBox)
Vue.component('label-box-mission', LabelBoxMission)
Vue.component('pagination', Pagination)
Vue.component('path-content', PathContent)
Vue.component('path-location', PathLocation)
Vue.component('select-box', SelectBox)
Vue.component('icon-media', IconMedia)
Vue.component('icon-folder', IconFolder)
Vue.component('tbl', Tbl)
Vue.component('text-upload', TextUpload)
Vue.component('text-box', TextBox)
Vue.component('text-box-2', TextBox2)
Vue.component('time-picker-box', TimePickerBox)
Vue.component('uploader-box', UploaderBox)
Vue.component('uploader-box-img', UploaderBoxImg)
Vue.component('uploader-box-tx', UploaderBoxTx)

Vue.component('bottom-info', BottomInfo)
Vue.component('modal-common', ModalCommon)



